import { appRoutesNames } from '../../app-routing/app.routes.names';

export const sidebarMenuOptions = {
  items: [
    {
      route: appRoutesNames.HOME,
      icon: 'assets/icons/i-menu.svg',
      name: 'Home',
      title: 'Home',
      isSelected: true,
      submenu:false,
      principal:true,
      id:1,
    },
    {
      route: appRoutesNames.CG_DYNAMIC_REPORT,
      icon: 'assets/icons/i-bar-chart.svg',
      name: 'Reportes de Inversión',
      title: 'Reportes de Inversión',
      description:
        'Acceda a la información más actualizada de sus inversiones mediante CG Dynamic Report, que ofrece análisis e informes financieros consolidados.',
      tooltip: false,
      openTab: true,
      submenu:false,
      principal:true,
      id:2,
      openTabPath: 'manuales',
    },
    {
      route: appRoutesNames.NETX,
      icon: 'assets/icons/i-netx.svg',
      name: 'Cuenta Pershing',
      title:'Acceso Pershing',
      description: '',
      openTab: true,
      tooltip: false,
      submenu:false,
      principal:true,
      id:3,
    },
    
    {
      route: appRoutesNames.CG_ALERNATIVES,
      icon: 'assets/icons/i-link.svg',
      name: 'Fondos Alternativos',
      title: 'Acceso Intralinks',
      description:
        'Acceso a la plataforma Intralinks, con información de los fondos de inversión (Feeders) y fondos extranjeros de capital privado en los que se encuentra invertido',
      tooltip: false,
      submenu:false,
      principal:true,
      id:4,
    },
   /*  {
      route: appRoutesNames.HOME,
      icon: 'assets/icons/i-sync.svg',
      name: 'Actualizar datos',
      title:'Actualizar datos',
      description:
        'Acceso a la información más reciente de sus inversiones con vistas de análisis e informes financieros consolidados',
      isSelected: false,
      principal:true,
      submenu:false,
      id:6,
    }, */
    {
      route: appRoutesNames.PORTAL_CLIENTES,
      icon: 'assets/icons/i-user.svg',
      name: 'Portal de clientes',
      title: 'Portal de clientes',
      submenu:true,
      principal:true,
      itemsubmenu:false,
      items:[],
      id:5,
      description:
        'Acceso a la información más reciente de sus inversiones con vistas de análisis e informes financieros consolidados.',
      operacion: 'Ahora también puedes realizar compras, ventas y transferencias.',
    },
    {
      route: appRoutesNames.DESCARGAS,
      icon: 'assets/icons/i-download-al.svg',
      name: 'Documentos',
      title: 'Documentos',
      description:
        'Visualización y descarga de certificados tributarios, comprobantes de operaciones, facturas y, movimientos en private equity',
      isSelected: false,
      principal:false,
      submenu:false,
      itemsubmenu:true,
      itemidsubmenu:3,
      id:6,
    },
    
    {
      route: appRoutesNames.TRANSACCION,
      icon: 'assets/icons/i-operaciones.svg',
      name: 'Transaccional',
      title:'Operaciones',
      description: '',
      openTab: false,
      tooltip: false,
      submenu:false,
      principal:false,
      itemsubmenu:true,
      itemidsubmenu:3,
      id:7,
    },
    {
      route: appRoutesNames.DESCARGAS,
      icon: 'assets/icons/i-download-al.svg',
      name: 'Documentos2',
      title: 'Descargas',
      description:
        '',
      isSelected: false,
      submenu:false,
      principal:false,
      itemsubmenu:true,
      itemidsubmenu:3,
      id:9,
    },
    {
      route: appRoutesNames.FONDOS,
      icon: 'assets/icons/i-info-ring.svg',
      name: 'fondos',
      title: 'Características fondos',
      description:
        '',
      isSelected: false,
      submenu:false,
      principal:false,
      itemsubmenu:true,
      itemidsubmenu:3,
      id:8,
    }
    /* {
      route: appRoutesNames.BENEFICIARIOS,
      icon: 'assets/icons/i-groups.svg',
      name: 'Beneficiarios',
      description:
        'Visualización y descarga de certificados tributarios, comprobantes de operaciones, facturas y, movimientos en private equity',
      isSelected: false,
    }, */
  ],
};
