<app-breadcum [data]="dataBreadcum"></app-breadcum>

<div class="contt mt-4 me-4 ms-4 mb-4">
  <h3 for="Bienvenida" class="title">Descargas</h3>
  <div class="fadeIn">
    <div class="row mt-3 mb-3">
      <div class="col-xl-3 col-sm-6">
        <label class="titleRageData form-label" for="Empresa">Empresa</label>
        <select class="form-select" (change)="onChangeBusiness($event)">
          <option *ngIf="!contracts.length">Seleccione una empresa</option>
          <option [value]="i" *ngFor="let empresa of companies; let i = index">
            {{ empresa.name }}
          </option>
        </select>
      </div>
      <div class="col-xl-3 col-sm-6 col-sm-6">
        <label class="titleRageData form-label" for="Empresa">Contrato</label>
        <select
          class="form-select"
          (change)="onChangeContract($event)"
          [disabled]="!contracts.length">
          <option *ngIf="!selectedContract" [selected]="changeBusiness">
            Seleccione un contrato
          </option>
          <option *ngFor="let contrato of contracts">
            {{ contrato }}
          </option>
        </select>
      </div>
      <div class="col-xl-3 col-sm-6 col-sm-6">
        <label class="titleRageData form-label" for="Empresa">Tipo de documento</label>
        <select
          class="form-select"
          (change)="onChangeDocumentType($event)"
          [disabled]="!documentType.length">
          <option *ngIf="!selectedDocument" [selected]="changeTypes">
            Seleccionar
          </option>
          <option value="{{documents.value}}" *ngFor="let documents of documentType">
            {{ documents.text }}
          </option>
        </select>
      </div>
    </div>
  </div>
</div>
<div class="contt mt-4 me-4 ms-4 mb-4" *ngIf="selectedType.length > 0">
  <div class="fadeIn">
    <div class="row mb-5">
      <ng-container *ngIf="selectedType === 'estados'">
        <div class="col-xl-3 col-sm-6 mt-xl-0 mt-sm-4">
          <label
            class="titleRageData form-label"
            for="Estados de cuenta"
            >Estados de cuenta</label
          >
          <select
            class="form-select selectFile"
            [class.highlight]="highlightMontly"
            (change)="onChangeMonthlyReport($event)">
            <option value="false">Estados de cuenta</option>
            <option
              [value]="i"
              [selected]="contract === selectedContract"
              *ngFor="let item of resumen; let i = index">
              {{ item.fecha }}
            </option>
          </select>
        </div>
      </ng-container>
      <ng-container *ngIf="selectedType === 'constancias'">
        <div class="col-xl-3 col-sm-6 mt-xl-0 mt-sm-4">
          <label
            class="titleRageData form-label"
            for="Constancias fiscales"
            >Constancias fiscales</label
          >
          <select
            class="form-select selectFile"
            (change)="onChangeAnnualReport($event)"
            [disabled]="!contracts.length"
            [class.highlight]="highlightAnnual">
            <option value="false">Constancias fiscales</option>
            <option
              [value]="i"
              [selected]="contract === selectedContract"
              *ngFor="let item of recordsByYear; let i = index">
              {{ item }}
            </option>
          </select>
        </div>
      </ng-container>
    </div>
    <hr>

    <div class="row mt-5 mb-4">
        
      <div class="d-flex flex-row col-md-12 col-sm-6 pos-rel" *ngIf="showExample">
        <span class="title">{{ selectedReport }} {{dateReport}} - Contrato {{selectedContract}} </span>
        <div class="pos-rel">
          <span
            class="material-symbols-outlined pointer alertIcon"
            (click)="showMessage()">
            info
          </span>
          <ng-container *ngIf="showMessageToDowload">
            <div class="alertDowload" [ngClass]="{'more-left': selectedReport.length > 50}">
              <span class="titleAlert vertical">
                Habilitar descargas masivas en tu navegador.
              </span>
              <br />
              <span>
                Para permitir descargas masivas en su sitio web, simplemente
                configura tu navegador dando permiso a este sitio. Haz clic en
                "descargas" y permite al sitio descargar múltiples archivos de
                una vez. Esto solo se hace una vez, y después podrás descargar
                varios archivos sin problemas.
              </span>
            </div>
          </ng-container>
        </div>
      </div>
  </div>
  <div class="row">
    <div class="col-auto" *ngIf="highlightAnnual">
      <button
        class="btn-alternative"
        (click)="dowlandZip()">
        <span>Descargar Archivos</span>
        <span class="material-symbols-outlined icon"> download </span>
      </button>
    </div>
  </div>
  <ng-container *ngIf="highlightMontly">
    <div class="row mt-3 mb-5">
      <div class="col-md-12 fadeIn">
        <iframe
          [src]="pdfPath | sanitizeUrl"
          style="width: 100%; height: calc(100vh - 40vh)"></iframe>
      </div>
    </div>
  </ng-container>
  </div>
</div>