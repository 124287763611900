/**
 * @author       Eduardo Leonardo Torres Guevara <eduardo.tg@ix.agency>
 * @copyright    2023 IX Agency.
 * @lastmodifiedDate 14.02.2023 13:00
 */
import { Component } from '@angular/core';

@Component({
  selector: 'app-contratos',
  templateUrl: './contratos.component.html',
  styleUrls: ['./contratos.component.scss'],
})
export class ContratosComponent {
  constructor() {}

  OnDestroy(): void {
    localStorage.removeItem('bussiness');
    localStorage.removeItem('contract');
    localStorage.removeItem('date');
  }
}
