import { Component, OnInit, Input } from '@angular/core';
import { BaseService } from '../../base/base.service';
import { clientesInterface } from './clientes-interface';
import { titles } from './titles-table';

/**
 * @autor Carlos Santiago <carlos.sp@ix.agency>
 */
@Component({
  selector: 'app-table-admin',
  templateUrl: './table-admin.component.html',
  styleUrls: ['./table-admin.component.scss'],
})
export class TableAdminComponent extends BaseService implements OnInit {
  ngOnInit(): void {
    this.sendDataToBreadcum(['Home', 'Beneficiarios']);
    this.getData();
    this.collection = '';
  }
  elements = ['Menú principal', 'Cerrar sesión'];
  posts: any;

  /**
   * Current page
   * @autor Carlos Santiago <carlos.sp@ix.agency>
   * @param {number} page number of current page
   */
  page: number;
  onCurrentPage(page: number) {
    this.page = page;
    this.sendData();
  }

  /**
   * Getting all clients data
   */
  data: Array<clientesInterface>;
  private getData() {
    this.collection = '/beneficiarios/admin';
    let verb = 'POST';
    this.send('', verb).subscribe(response => {
      this.data = response.data;
    });
  }

  /**
   * Its used to get data with the current page
   * @autor Carlos Santiago <carlos.sp@ix.agency>
   */
  dataReceived: Array<clientesInterface>;
  dataClients: Array<clientesInterface>;
  totalPages: number;
  sendData() {
    this.collection = '/beneficiarios/admin';
    let obj = {
      elements: 8,
      page: this.page,
    };
    if (this.elements_table != 0) {
      obj.elements = this.elements_table;
    }

    this.send(obj).subscribe(response => {
      this.dataReceived = response.data.elements_page;
      this.dataClients = this.dataReceived;
      this.totalPages = response.data.number_of_pages;
    });
  }

  /**
   * Headers
   */
  headers = titles;
  /**
   * Search
   */
  searchClient: string = '';
  /**
   * Select Options
   */
  selectOptions = [
    { id: 1, state: 'Todos' },
    { id: 2, state: 'Actualizado' },
    { id: 3, state: 'Por vencer' },
    { id: 4, state: 'Notificado' },
    { id: 5, state: 'Vencido' },
    { id: 6, state: 'Espera firma' },
  ];
  selectedValue: number = 0;
  selectOption: string = '';

  restart() {
    this.loader.show(true);
    this.dataClients = this.dataReceived;
    this.searchClient = '';
    this.selectedValue = 0;
    this.loader.show(false);
  }

  /**
   * Its used to search Clients
   * @autor Carlos Santiago <carlos.sp@ix.agency>
   */
  search() {
    this.loader.show(true);
    this.collection = '/beneficiarios/admin';
    this.selectOption = this.selectOptions[this.selectedValue - 1].state;

    let obj = {
      client: this.searchClient,
      status: this.selectOption,
    };
    if (this.selectOption === 'Todos' && this.searchClient === '') {
      this.sendData();
      this.loader.show(false);
    } else if (this.selectOption === 'Todos' && this.searchClient != '') {
      obj.status = '';
      this.send(obj).subscribe(response => {
        this.dataClients = response.data;
        this.loader.show(false);
      });
    } else {
      this.send(obj).subscribe(response => {
        this.dataClients = response.data;
        this.loader.show(false);
      });
    }
  }

  /**
   *
   * @param breadcum
   */
  private sendDataToBreadcum(breadcum: string[]) {
    this.titleContainerService.show(
      true,
      breadcum,
      'Resumen de beneficiarios',
      'En este resumen podras, lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu.'
    );
  }

  /**
   * Test
   */
  elements_table: number = 0;
  elements_to_show(elements: number) {
    this.elements_table = elements;
    this.sendData();
  }
}
