<div class="d-flex flex-column">
  <div class="container">
    <div class="content bg-white">
      <div class="col-md-4 col-sm-12">
        <select class="f-16 weight-400 form-select" (change)="onSelectDownloadFile($event)">
          <option [value]="'non-selected'">Seleccionar tipo de archivo</option>
          <option *ngFor="let file of fileOptions; let i = index" [value]="i">
            {{ file.nombre }}
          </option>
        </select>
      </div>
    </div>
    <ng-container *ngIf="fieldSelected.length > 0">
      <form [formGroup]="myForm" (ngSubmit)="onSubmitForm(myForm)">
        <div class="content row custom_row flex-wrap bg-white ml-0-i">
          <div class="col-md-3 col-sm-12" *ngFor="let item of fieldSelected">
            <ng-container *ngIf="item.tipo === 'Select'">
              <p>{{ item.nombre }}</p>
              <select
                class="f-16 weight-400 form-select"
                formControlName="{{ item.nombre }}"(change)="validateAllIsFill()">
                <option [ngValue]="0" selected disabled>Seleccionar</option>
                <option
                  *ngFor="let item of item.campos; let i = index"
                  [ngValue]="item.id">
                  {{ item.nombre }}
                </option>
              </select>
            </ng-container>
            <ng-container *ngIf="item.tipo === 'Date'">
              <p>{{ item.nombre }}</p>
              <input
                class="input_primary"
                type="date"
                [min]="
                  (item.fechaInicial != null
                    ? formatDate(item.fechaInicial)
                    : '01/01/2000'
                  ) | date : 'yyyy-MM-dd'
                "
                [max]="
                  (item.fechaFinal != null
                    ? formatDate(item.fechaFinal)
                    : '01/01/2030'
                  ) | date : 'yyyy-MM-dd'
                "
                formControlName="{{ item.nombre }}" />
            </ng-container>
          </div>
          <div class="col-md-3 col-sm-12 d-flex flex-column">
            <p>&nbsp;</p>
            <button
              class="btn btn-primary button justify-content-between"
              [disabled]="!myForm.valid">
              <span> Consultar </span>
              <span class="material-symbols-outlined f-14">
                arrow_forward_ios
              </span>
            </button>
          </div>
          <ng-container *ngIf="showDownloadSection">
            <div class="custom_row flex-wrap bg-white mt-3">
              <div class="col-md-3 col-sm-12">
                <button
                  class="btn btn-alternative button d-flex justify-content-between"
                  type="button"
                  (click)="showFile()">
                  <span> Descargar archivos </span>
                  <span class="material-symbols-outlined"> download </span>
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </form>
    </ng-container>
  </div>
  <!-- <button class="btn" (click)="recibir()">Recibir datos</button>
  <button (click)="enviar()">enviar</button> -->
</div>
