import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/base/base.service';
import { flowInterface } from 'src/app/common/dtos/contractsDTOs';
import { ExportService } from 'src/app/util/exportFiles/export.service';

@Component({
  selector: 'app-flujos',
  templateUrl: './flujos.component.html',
  styleUrls: ['./flujos.component.scss'],
})
export class FlujosComponent extends BaseService implements OnInit {

  
  /**
   * @property {Array} List for rows table
   * @public
   */
  public data: flowInterface[] = [];
  /**
   * @property {Array} List for available data
   * @public
   */
  public available = [];
  /**
   * @property {Object} Selected bussines data
   * @private
   */
  private bussines: any = {};
  /**
   * @property {string} Id Contract for query params
   * @private
   */
  private idContract: string = '';
  /**
   * @property {string} Date for query params
   * @private
   */
  private startDate: string = '';

  isDownloading: boolean = false;

  ngOnInit(): void {
    this.dataBreadcum = [{
      breadcum: ['Home', 'Portal de clientes','Resumen del portafolio', 'Flujos'],
      title: 'Flujos',
      subTitle : '',
      icon:false
     }]
  }

  public getContactData(contactData) {
    this.loaderTransparent.show(true);
    this.bussines = contactData.bussines;
    this.idContract = contactData.contract;
    this.startDate = contactData.date;
    this.getAll(
      '/contract/' +
        this.idContract +
        '/flow?fecha=' +
        this.startDate +
        '&contrato=' +
        this.idContract
    ).subscribe(response => {
      let data = response['data'] ? response.data.rows : [];
      if (data.length > 0) {
        this.available = response.data.available || [];
        this.data = data.map(item => ({
          concept: this.formatText(item[0]),
          today: item[1],
          twentyfour: item[2],
          fourtyeight: item[3],
          seventytwo: item[4],
          total: item[5],
        }));
      }

      setTimeout(() => {
        this.loaderTransparent.show(false);
      }, 1000);
    });
  }

  formatText(string) {
    let formatString = string.toString().toLowerCase();
    return formatString.charAt(0).toUpperCase() + formatString.slice(1);
  }

  async printPDF() {
    const empresa = this.bussines.name;
    const fecha = this.formatDate(this.startDate);
    this.isDownloading = true;
    const id = this.idContract;

    const imgH = await ExportService.getImageFromLocal(
      '/assets/images/logo.png'
    );
    //var flowDiv = await ExportService.convertHTMLtoImage('flowDiv');

    const date = this.formatDateTime(new Date());

    const pdfTHeaders = [
      { text: ' ', bold: true, fontSize: 8, alignment: 'center' },
      { text: 'Hoy', bold: true, fontSize: 8, alignment: 'center' },
      { text: '24 hrs', bold: true, fontSize: 8, alignment: 'center' },
      { text: '48 hrs', bold: true, fontSize: 8, alignment: 'center' },
      { text: '72 hrs', bold: true, fontSize: 8, alignment: 'center' },
      { text: 'Total', bold: true, fontSize: 8, alignment: 'center' },
    ];

    let rows: [any[]] = [pdfTHeaders];

    for (let row of this.data) {
      let r = [
        { text: row.concept.toString(), fontSize: 8, alignment: 'left' },
        {
          text: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(parseFloat(row.today.toString().toString())),
          fontSize: 8,
          alignment: 'right',
        },
        {
          text: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(parseFloat(row.twentyfour.toString())),
          fontSize: 8,
          alignment: 'right',
        },
        {
          text: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(parseFloat(row.fourtyeight.toString())),
          fontSize: 8,
          alignment: 'right',
        },
        {
          text: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(parseFloat(row.seventytwo.toString())),
          fontSize: 8,
          alignment: 'right',
        },
        {
          text: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(parseFloat(row.total.toString())),
          fontSize: 8,
          alignment: 'right',
        },
      ];
      rows.push(r);
    }

    var docDefinition = {
      pageOrientation: 'landscape',
      pageMargins: [20, 150, 10, 30],
      header: function (currentPage, pageCount) {
        return [
          { image: imgH, width: 150, margin: 20 },
          {
            text: 'Contrato ' + id + ' | "' + empresa + '"',
            margin: [20, 0, 0, 10],
            bold: true,
          },
          { text: 'Fecha: ' + fecha, margin: [20, 0, 0, 10] },
        ];
      },
      footer: function (currentPage, pageCount) {
        return [
          {
            columns: [
              {
                text: 'Fecha y hora de descarga: ' + date,
                alignment: 'left',
                width: 'auto',
                margin: [20, 0, 0, 0],
              },
              {
                text: 'Página ' + currentPage.toString() + ' de ' + pageCount,
                alignment: 'right',
                margin: [0, 0, 20, 0],
              },
            ],
          },
        ];
      },
      content: [
        {
          text: 'Flujos',
          margin: [0, 0, 0, 10],
          fontSize: 18,
          color: '#002FA7',
        },
        {
          layout: 'noBorders',
          table: {
            headerRows: 1,
            widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
            body: rows,
          },
        },
      ],
      styles: {
        header: {
          background: 'red',
          lineHeight: 3,
        },
      },
    };

    let rowsExcel: [any[]] = [
      [' ', 'Hoy', '24 hrs', '48 hrs', '72 hrs', 'Total'],
    ];

    for (let row of this.data) {
      let r = [
        row.concept.toString(),
        { t: 'n', v: row.today.toString() },
        { t: 'n', v: row.twentyfour.toString() },
        { t: 'n', v: row.fourtyeight.toString() },
        { t: 'n', v: row.seventytwo.toString() },
        { t: 'n', v: row.total.toString() },
      ];

      rowsExcel.push(r);
    }

    const tables = [{ rows: rowsExcel, name: 'Flujo' }];
    await ExportService.exportToExcel(tables, 'flujos.xlsx');

    ExportService.exportToPDF(docDefinition, false);

    this.isDownloading = false;
  }

  private formatDateTime(date) {
    let dateFormat = new Date(date);

    let day = ('0' + dateFormat.getDate().toString()).slice(-2);
    let month = ('0' + (dateFormat.getMonth() + 1).toString()).slice(-2);
    let year = dateFormat.getFullYear().toString().toString();
    let hour = ('0' + dateFormat.getHours().toString()).slice(-2);
    let minutes = ('0' + dateFormat.getMinutes().toString()).slice(-2);
    let seconds = ('0' + dateFormat.getSeconds().toString()).slice(-2);

    return (
      day +
      '/' +
      month +
      '/' +
      year +
      ' ' +
      hour +
      ':' +
      minutes +
      ':' +
      seconds
    );
  }

  private formatDate(date) {
    let dateFormat = new Date(date);

    let day = ('0' + dateFormat.getUTCDate().toString()).slice(-2);
    let mesTmp = dateFormat.getUTCMonth();
    let month = ('0' + (mesTmp + 1).toString()).slice(-2);
    let year = dateFormat.getFullYear().toString().toString();

    return day + '/' + month + '/' + year;
  }
}
