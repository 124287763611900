/**
 * @author       Eduardo Leonardo Torres Guevara <eduardo.tg@ix.agency>
 * @copyright    2022 IX Agency.
 * @lastmodifiedDate 01/09/2023 12:40
 */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

import { RequestService } from '../../request/request.service';

// Import the AuthService type from the SDK
// Config
@Injectable()
export class SessionService {
  accessToken: string | null;
  refreshToken = '';
  authToken = '';
  timeout = '';

  /**
   *
   * @param router Reference to Router Angular class
   * @param requestService Reference to RequestService class
   * @class SessionService
   * @constructor
   */
  constructor(
    private router: Router,
    private requestService: RequestService,
    public auth: AuthService,
    @Inject(DOCUMENT) public document: Document
  ) {
    this.accessToken = localStorage.getItem('accessToken') || '{}';
    this.refreshToken = localStorage.getItem('RefreshToken') || '{}';
    this.authToken = localStorage.getItem('IdToken') || '{}';
    
  }

  /**
   * @description Set in the current session and the local storage the values
   * @param credentials {Object} Data recover from the API request
   */

  create(credentials: any) {
    this.accessToken = credentials['accessToken']['jwtToken'];
    this.refreshToken = credentials['refreshToken']['token'];
    this.authToken = credentials['idToken']['jwtToken'];

    //-
    localStorage.setItem('accessToken', this.accessToken || '');
    localStorage.setItem('refreshToken', this.refreshToken);
    localStorage.setItem('authToken', this.authToken);
    //-
    this.requestService.updateHeaders('accessToken', `${this.accessToken}`);
    this.requestService.updateHeaders('refreshToken', `${this.refreshToken}`);
    this.requestService.updateHeaders('authToken', `${this.authToken}`);
    this.router.navigate([`/dashboard/home`]);
  }
  /**
   * @description Remove the values from the current session and the local storage
   */
  public destroy(route?: string): void {
    route = route === undefined ? '/login' : route;
    this.accessToken = null;
    this.refreshToken = '';
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('authToken');
    localStorage.removeItem('email');
    localStorage.removeItem('userName');
    localStorage.clear();
    this.requestService.removeHeader('accessToken');
    this.requestService.removeHeader('refreshToken');
    this.requestService.removeHeader('authToken');
    this.auth
      .logout({
        logoutParams: { returnTo: document.location.origin },
      })
      .subscribe(() => {
        setTimeout(() => {
          this.router.navigate([route]);
        }, 1000);
      });
  }
  /**
   * @description Check if exist a token in the current session
   * @return \{{{boolean}}\} Return true if exist a token
   */
  public inSession(route?: string): boolean {
    if (localStorage.getItem('accessToken') === null) {
      this.destroy(route);
      return false;
    } else if (
      this.accessToken !== 'null' &&
      this.accessToken !== null &&
      this.accessToken !== undefined
    ) {
      return true;
    }
    return false;
  }

  /**
   * @description Check if exist a token in 2FA component
   * @return \{{{boolean}}\} Return true if exist a token
   */
  public inTwoFaSession(): boolean {
    if (localStorage.getItem('accessToken') === null) {
      return false;
    } else if (
      this.accessToken !== 'null' &&
      this.accessToken !== null &&
      this.accessToken !== undefined
    ) {
      return true;
    }
    return false;
  }

  getEmail(): string {
    let email: string = localStorage.getItem('email') || '';
    if (
      email === 'eduardo.tg@ix.agency' ||
      email === 'lety.gr@ix.agency' ||
      email === 'carlos.sp@ix.agency' ||
      email === 'roberto.rc@ix.agency' ||
      email === 'jorge.go@ix.agency' ||
      email === 'jglez74@hotmail.com' ||
      email === 'jorge.pt@ix.agency' ||
      email === 'jose.rz@ix.agency' ||
      email === 'mariana.bs@ix.agency'
    ) {
      //email = 'enrique.pina@cgcompass.com'; //andre.millan.m@gmail.comafigueos@gmail.com
      email = 'julio.tolentino@cgcompass.com';
      //email = 'miguel.tenorio@cgcompass.com';
    }
    return email;
  }
}
