import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TitleContainerComponent } from '../common/title-container/title-container.component';
import { TitleContainerService } from '../common/title-container/title-container.service';
import { TemplateModule } from '../template/template.module';
import { DashboardComponent } from './dashboard.component';
import { DescargasComponent } from './descargas/descargas.component';
import { HomeComponent } from './home/home.component';
import { ManualUsuariosComponent } from './manual-usuarios/manual-usuarios.component';

// Local Components
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TemplateModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    DashboardComponent,
    DescargasComponent,
    HomeComponent,
    TitleContainerComponent,
    ManualUsuariosComponent,
  ],
  exports: [DashboardComponent],
  providers: [TitleContainerService],
})
export class DashboardModule {}
