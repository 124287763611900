import { ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null as any;
      }

      const valid = regex.test(control.value);

      return valid ? (null as any) : error;
    };
  }

  public passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password')?.value;
    const confirmPassword: string = control.get('confirmPassword')?.value;

    var re = /^(?=.*\d)(?=.*[!@#$%^&*-.])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    let validationCharacters = re.test(password);
    if (!validationCharacters) {
      control.get('password')!.setErrors({ NoPassswordMatch: true });
      return;
    }
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control

      control.get('confirmPassword')!.setErrors({ NoPassswordMatch: true });
    }

    return true;
  }

  static passwordMatchingValidatior: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const password = control.get('password')!;
    const confirmPassword = control.get('confirmPassword');

    var re = /^(?=.*\d)(?=.*[!@#$%^&*-.])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    let validationCharacters = re.test(password.value);
    if (!validationCharacters) return { notmatched: true };

    return password?.value === confirmPassword?.value
      ? null
      : { notmatched: true };
  };
}
