<aside class="" [ngClass]="{'sidebar bigger': !isOpen,'sidebar_phone': isOpen }">
  <nav class="main-menu" id="menu">
    <ul *ngIf="menu_items.length > 0" class="nav_items">
      <ng-container  *ngFor="let item of menu_items">
        <li class="tooltipp"
        [ngClass]="{ selected: item.isSelected, tooltipp: item.tooltip }"
        (click)="routerToPath(item.route, item)">
        
        <a class="h-100">
          <div class="d-flex">
            <img src="{{ item.icon }}" alt="{{ item.title }}" />
            <span class="nav-text title_menu"> {{ item.title }} </span>
            <span class="tooltiptextt" *ngIf="item.tooltip"
              >Al acceder a este enlace, serás redirigido fuera de esta
              página</span
            >
          </div>
        </a>
      </li>


        <!-- <li *ngIf="item.principal && !item.submenu"
          class="tooltipp"
          [ngClass]="{ selected: item.isSelected, tooltipp: item.tooltip }"
          (click)="routerToPath(item.route, item)">

          <a class="h-100">
            <div class="d-flex">
              <img src="{{ item.icon }}" alt="{{ item.title }}" />
              <span class="nav-text title_menu"> {{ item.title }} </span>
              <span class="tooltiptextt" *ngIf="item.tooltip"
                >Al acceder a este enlace, serás redirigido fuera de esta
                página</span
              >
            </div>
          </a>
        </li> -->

        <!-- <li *ngIf="item.principal && item.submenu"
          class="tooltipp"
          [ngClass]="{ selected: item.isSelected, tooltipp: item.tooltip, noselected: selectedsubmenu }">

          <a class="h-100">
            <div class="d-flex">
              <img src="{{ item.icon }}" alt="{{ item.title }}" />
              <span class="nav-text title_menu"> {{ item.title }} </span>
              <span class="tooltiptextt" *ngIf="item.tooltip"
                >Al acceder a este enlace, serás redirigido fuera de esta
                página</span
              >
            </div>
          </a>
          <ng-container  *ngIf="item.submenu">
          
            <ul class="submenu">
            <ng-container  *ngFor="let items of menu_items">
                <ng-container  *ngIf="item.id === items.itemidsubmenu">
                  <li  [ngClass]="{ selected: items.itemsubmenu && items.isSelected}"  (click)="routerToPath(items.route, items)">
                    <a class="h-100">
                      <div class="d-flex">
                        <img src="{{ items.icon }}" alt="{{ items.title }}" />
                        <span class="nav-text title_menu"> {{ items.title }} </span>
                      </div>
                    </a>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </ng-container>
        </li> -->
      </ng-container>
    </ul>
  </nav>
</aside>

<!-- <aside class="sidebar bigger">
  <nav>
    <ul  class="nav_items">
      <li
        class="tooltipp"
        [ngClass]="{ selected: menu_items[0].isSelected, tooltipp: menu_items[0].tooltip }" 
        (click)="routerToPath(menu_items[0].route, menu_items[0])"
        >
        <a class="h-100">
          <div class="d-flex">
            <img src="{{menu_items[0].icon }}" alt="{{ menu_items[0].title }}" />
            <span class="nav-text title_menu"> {{ menu_items[0].title }} </span>
            <span class="tooltiptextt" *ngIf="menu_items[0].tooltip"
            >Al acceder a este enlace, serás redirigido fuera de esta
            página</span
            >
          </div>
        </a>
       
      </li>
      <li
      class="tooltipp"
      [ngClass]="{
        selectedsub:  menu_items[1].submenu}"
        >
      <a class="h-100">
        <div class="d-flex">
          <img src="{{menu_items[1].icon }}" alt="{{ menu_items[1].title }}" />
          <span class="nav-text title_menu"> {{ menu_items[1].title }} </span>
          <span class="tooltiptextt" *ngIf="menu_items[1].tooltip"
          >Al acceder a este enlace, serás redirigido fuera de esta
          página</span
          >
        </div>
      </a>
       <ul class="submenu" *ngIf="menu_items[1].submenu">
          <li  [ngClass]="{ selected: menu_items[2].itemsubmenu && menu_items[2].isSelected}"  (click)="routerToPath(menu_items[2].route, menu_items[2])">
            <a class="h-100">
              <div class="d-flex">
                <img src="{{ menu_items[2].icon }}" alt="{{ menu_items[2].title }}" />
                <span class="nav-text title_menu"> {{ menu_items[2].title }} </span>
              </div>
            </a>
          </li>
          <li  [ngClass]="{ selected: menu_items[3].itemsubmenu && menu_items[3].isSelected}"  (click)="routerToPath(menu_items[3].route, menu_items[3])">
            <a class="h-100">
              <div class="d-flex">
                <img src="{{ menu_items[3].icon }}" alt="{{ menu_items[2].title }}" />
                <span class="nav-text title_menu"> Descargas </span>
              </div>
            </a>
          </li>
        </ul>
    </li>
    
    </ul>
  </nav>
</aside>
 -->

<!-- <div class="container_phone_menu" *ngIf="menuOpen">
  <aside class="sidebar_phone phone">
    <nav class="w-full">
      <ul *ngIf="menu_items.length > 0" class="nav_items">
        <li class="close">
          <span
            class="material-symbols-outlined pointer close"
            (click)="toggleMenu()">
            close
          </span>
        </li>
        <li
          *ngFor="let item of menu_items"
          class="tooltipp"
          [ngClass]="{ selected: item.isSelected, tooltipp: item.tooltip }"
          (click)="routerToPath(item.route, item)">
          <a class="h-100">
            <div class="d-flex phone_margin_a">
              <img src="{{ item.icon }}" alt="{{item.title}}" />              
              <span class="nav-text title_menu"> {{ item.title }} </span>
            </div>
          </a>
        </li>
        
      </ul>
    </nav>
  </aside>
</div>
 -->
<!-- <aside class="sidebar bigger">
  <nav>
    <ul *ngIf="menu_items.length > 0" class="nav_items">
      <li
        *ngFor="let item of menu_items"
        class="tooltipp"
        [ngClass]="{ selected: item.isSelected, tooltipp: item.tooltip }"
        (click)="routerToPath(item.route, item)">
        <a class="h-100">
          <div class="d-flex">
            <img src="{{ item.icon }}" alt="{{ item.title }}" />
            <span class="nav-text title_menu"> {{ item.title }} </span>
            <span class="tooltiptextt" *ngIf="item.tooltip"
              >Al acceder a este enlace, serás redirigido fuera de esta
              página</span
            >
          </div>
        </a>
      </li>
    </ul>
  </nav>
</aside> -->
<!-- <aside class="sidebar bigger">
  <nav>
    <ul  class="nav_items">
      <li
        class="tooltipp"
        [ngClass]="{ selected: menu_items[0].isSelected, tooltipp: menu_items[0].tooltip }" 
        (click)="routerToPath(menu_items[0].route, menu_items[0])"
        >
        <a class="h-100">
          <div class="d-flex">
            <img src="{{menu_items[0].icon }}" alt="{{ menu_items[0].title }}" />
            <span class="nav-text title_menu"> {{ menu_items[0].title }} </span>
            <span class="tooltiptextt" *ngIf="menu_items[0].tooltip"
            >Al acceder a este enlace, serás redirigido fuera de esta
            página</span
            >
          </div>
        </a>
       
      </li>
      <li
      class="tooltipp"
      [ngClass]="{
        selectedsub:  menu_items[1].submenu && menu_items[2].isSelected}"
        >
      <a class="h-100">
        <div class="d-flex">
          <img src="{{menu_items[1].icon }}" alt="{{ menu_items[1].title }}" />
          <span class="nav-text title_menu"> {{ menu_items[1].title }} </span>
          <span class="tooltiptextt" *ngIf="menu_items[1].tooltip"
          >Al acceder a este enlace, serás redirigido fuera de esta
          página</span
          >
        </div>
      </a>
       <ul class="submenu" *ngIf="menu_items[1].submenu">
          <li class="xx" [ngClass]="{ selected: menu_items[2].itemsubmenu && menu_items[2].isSelected}"  (click)="routerToPath(menu_items[2].route, menu_items[2])">
            <a class="h-100">
              <div class="d-flex">
                <img src="{{ menu_items[2].icon }}" alt="{{ menu_items[2].title }}" />
                <span class="nav-text title_menu"> {{ menu_items[2].title }} </span>
              </div>
            </a>
          </li>
          <li  [ngClass]="{ selected: menu_items[3].itemsubmenu && menu_items[3].isSelected}"  (click)="routerToPath(menu_items[3].route, menu_items[3])">
            <a class="h-100">
              <div class="d-flex">
                <img src="{{ menu_items[3].icon }}" alt="{{ menu_items[2].title }}" />
                <span class="nav-text title_menu"> Descargas </span>
              </div>
            </a>
          </li>
        </ul>
    </li>
    
    </ul>
  </nav>
</aside> -->

<!-- <div class="container_phone_menu" *ngIf="menuOpen">
  <aside class="sidebar_phone phone">
    <nav class="w-full">
      <ul *ngIf="menu_items.length > 0" class="nav_items">
        <li class="close">
          <span
            class="material-symbols-outlined pointer close"
            (click)="toggleMenu()">
            close
          </span>
        </li>
        <li
          *ngFor="let item of menu_items"
          class="tooltipp"
          [ngClass]="{ selected: item.isSelected, tooltipp: item.tooltip }"
          (click)="routerToPath(item.route, item)">
          <a class="h-100">
            <div class="d-flex phone_margin_a">
              <img src="{{ item.icon }}" alt="{{item.title}}" />              
              <span class="nav-text title_menu"> {{ item.title }} </span>
            </div>
          </a>
        </li>
        
      </ul>
    </nav>
  </aside>
</div>
 -->