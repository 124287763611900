
<div class="container">
    <div class="content">
        <div class="row">
            <div class="col-12 mb-4">
                <p class="subtitle">Destino de los recursos</p>
                <span class="description">Efectivo disponible {{saldoEfectivo}}</span>
            </div>
            <hr>
            <div class="col-2"></div>
            <div class="col-8 row">
                <ng-container *ngIf="operacion ==='chequera'">
                    <div class="col-12">
                        <div class="row mt-3 mb-3">
                            <div class="col-8 mb-3 mx-auto">
                                <span class="text-grey fs-14 weight-700">Destino de los recursos</span>
                            </div>
                            <div class="col-8 mx-auto">
                                <div class="mb-3 row">
                                    <label for="chequera" class="col-sm-4 col-form-label text-grey fs-14 weight-400">Seleccionar</label>
                                    <div class="col-sm-8">
                                        <select class="form-select" id="chequera" name="chequera" [(ngModel)]="dataOperation.cuenta">
                                            <option [value]="''" selected>Seleccionar banco</option>
                                            <ng-container *ngFor="let item of data_bancos">
                                                <option value="{{item.liq}}">{{item.tarjetatext}}</option>
                                            </ng-container>
                                          </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-8 mx-auto">
                                <div class="mb-3 row">
                                    <div class="offset-md-4 col-8">
                                        <label for="chequera" class="text-grey fs-14 weight-700 col-form-label">Importe a transferir</label>
                                        <input type="text" class="form-control" id="efectivod" name="efectivod" [(ngModel)]="dataOperation.monto" (blur)="cambiarformato()" >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="operacion === 'compven'">
                    <div class="col-12">
                        <div class="row mt-3 mb-3">
                            <div class="col-12 mb-3 ms-auto me-auto">
                                <div class="col-12">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="chequera" (click)="selectItem(1)">
                                        <label class="form-check-label item" for="chequera" [ngClass]="{'item-active' : btnActive}">
                                        Transferir a chequera
                                        </label>
                                    </div>
                                    <div class="col-12" *ngIf="btnActive">
                                        <div class="mb-3 row">
                                            <label for="chequera" class="col-sm-4 col-form-label">Seleccionar</label>
                                            <div class="col-sm-8">
                                                <select class="form-select" id="chequera" name="chequera" [(ngModel)]="dataOperation.cuenta">
                                                    <option [value]="''" selected>Seleccionar banco</option>
                                                    <ng-container *ngFor="let item of data_bancos">
                                                        <option value="{{item.liq}}">{{item.tarjetatext}}</option>
                                                    </ng-container>
                                                  </select>
                                            </div>
                                        </div>
                                        <div class="mb-3 row">
                                            <div class="offset-md-4 col-8">
                                                <span class="text-grey fs-14 weight-700">Importe a transferir</span>
                                                <div class="form-check mb-2 mt-2">
                                                    <label class="form-check-label items ms-1" [ngClass]="{'item-active' : btnimportetotal}" for="imptotal">
                                                        Importe total de la venta ${{saldoEfectivo}}
                                                        <input class="form-check-input" type="radio" name="Importes" id="imptotal" [checked]="btnimportetotal" (click)="selectImporte(1)">
                                                    </label>
                                                </div>
                                                <div class="form-check d-flex align-items-center">
                                                    <div class="col-5">
                                                        <label class="form-check-label items ms-1" [ngClass]="{'item-active' : !btnimportetotal}" for="otromonto">
                                                            Otro monto
                                                            <input class="form-check-input" type="radio" name="Importes" id="otromonto" (click)="selectImporte(2)">
                                                        </label>
                                                    </div>
                                                    <div class="col-6">
                                                        <input type="text" class="form-control" id="efectivod" name="efectivod" [disabled]="btnimportetotal" [(ngModel)]="dataOperation.monto" (blur)="cambiarformato()">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="form-check">
                                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="saldo" [checked]="!btnActive" (click)="selectItem(2)">
                                        <label class="form-check-label item" [ngClass]="{'item-active' : !btnActive}" for="saldo">
                                        Agregar a efectivo disponible
                                        </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div class="col-12 text-center">
                    <button type="button" class="btn-alternative plr-60 me-3" (click)="returnBack()">Regresar</button>
                    <button type="button" class="btn-alternative-alternative plr-60" (click)="AceptarTransaccion()">Aceptar</button>
                </div>
            </div>
            <div class="col-2"></div>
            
            
        </div>
    </div>
</div>