import { Component, OnDestroy, OnInit } from '@angular/core';

import { sidebarMenuOptions } from 'src/app/common/constants/constants';
import { TitleContainerService } from 'src/app/common/title-container/title-container.service';
import { DashboardComponent } from '../dashboard.component';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/common/modal/modal.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  constructor(
    public dashboard: DashboardComponent,
    private titleContainerService: TitleContainerService,
    public router: Router,
    public modal: ModalService
  ) {
    localStorage.setItem('itemSelected','Home');
  }
  public user = '';

  menu_items: any = [];

  subscription: Subscription;

  public products = [];

  item_selected: any = {};

  public showBanner = false;

  public message_modal:string = "";
  public validationtextoOperacion:any = [];


  async ngOnInit() {
    
    this.titleContainerService.show(false);
    this.message_modal = "Al hacer click en este link usted será redirigido a otro sitio de Internet que no es administrado por Compass. No ejercemos ningún control sobre el administrador de dicho sitio ni tampoco lo respaldamos como organización, empresa o producto. Si accede al sitio vinculado al enlace, lo hará por su cuenta y riesgo. Compass no ofrece ninguna declaración ni garantía respecto al sitio enlazado incluyendo su contenido, respecto del cual deberá referirse a las políticas y condiciones de uso del sitio web anfitrión.";
    this.dashboard.sendUser.subscribe((response: any) => {
      this.user = response.user;
      let arrayTransaccion:any = [...response.permission];
      arrayTransaccion = arrayTransaccion.slice(6,8);

      if (arrayTransaccion[0].allow === true && arrayTransaccion[1].allow === true) {
        this.validationtextoOperacion.allow = true;
       }
    });
    let observable = this.dashboard.currentPermitions;
    this.subscription = observable.subscribe((response: any) => {
      sidebarMenuOptions.items.forEach(item => {
        if (response.includes(item.name)) this.menu_items.push(item);
      });
      if (this.menu_items.length > 1) {
        this.item_selected = this.menu_items[1];
        this.showBanner = true;
      } else {
        this.item_selected = this.menu_items[0];
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openView(path: string) {
    switch (path) {
      case 'cg-dynamic-report':
        //this.modal.show(true,this.message_modal,'https://login.cgcompass.com/samlp/WafyDF9S6DVHKcRpg0cIf0qODg1l5w14');
        window.open(
          'https://login.cgcompass.com/samlp/WafyDF9S6DVHKcRpg0cIf0qODg1l5w14'
        );
        break;
      case 'cg-alternatives':
        //this.modal.show(true,this.message_modal,'https://iam.intralinks.com/idp/login/?applicationid=98e3df54-2ef1-48b4-82d4-02b83d273d23&br=bac14941-e126-4cfa-9984-7fe254084cfe&hostname=services.intralinks.com');
        window.open(
          'https://iam.intralinks.com/idp/login/?applicationid=98e3df54-2ef1-48b4-82d4-02b83d273d23&br=bac14941-e126-4cfa-9984-7fe254084cfe&hostname=services.intralinks.com'
        );
        break;
      case 'NetX':
        //this.modal.show(true,this.message_modal,'https://www.netxinvestor.com/nxi/welcome?nextPage=https:%2F%2Fwww.netxinvestor.com%2Fnxi%2Foverview');
        window.open(
          'https://www.netxinvestor.com/nxi/welcome?nextPage=https:%2F%2Fwww.netxinvestor.com%2Fnxi%2Foverview'
        );
        break;
      case 'portal-clientes':
        this.router.navigate([path]);
        break;
      default:
        this.router.navigate(['dashboard/' + path]);
        break;
    }
  }
}
