import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/app/base/base.service';

@Component({
  selector: 'app-form-client-data',
  templateUrl: './form-client-data.component.html',
  styleUrls: ['./form-client-data.component.scss'],
})
export class FormClientDataComponent extends BaseService implements OnInit {
  /*
   * Send form to principal form
   */
  @Output() formReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  ngOnInit(): void {
    this.myForm = this.fb.group({
      nation: ['', Validators.required],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      second_surname: ['', Validators.required],
      rut: ['', Validators.required],
      origin: ['', Validators.required],
      relasionship: ['', Validators.required],
      sign_place: ['', Validators.required],
      sign_date: ['', Validators.required],
    });

    this.formReady.emit(this.myForm);
  }
}
