import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseService } from 'src/app/base/base.service';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-confirmar',
  templateUrl: './confirmar.component.html',
  styleUrls: ['./confirmar.component.scss'],
})
export class ConfirmarComponent extends BaseService implements OnInit {
  @Input() data: any = [];
  @Input() series: any = [];
  @Input() contrato: string;
  public coords: any = {};
  @Output() dataToConfirm = new EventEmitter<string>();
  public startDate: string = '';
  public forma_pago: any = [];
  public concentradoras: any = [];
  public imdbase: any;
  public img: string = '';
  public responseGO: any = [];
  toast: any;
  public stepTexts: any = {};
  public stepStatus: boolean = false;
  public accion: string = '';

  async ngOnInit() {
    this.series = this.series[0];
    this.startDate = this.getFechaHoy();
    this.img = '/assets/images/logo.png';
    this.data.efectivo = '';
    this.coords.latitud = localStorage.getItem('latitude');
    this.coords.longitud = localStorage.getItem('longitude');

    if (this.data.operacion === 'comprar') {
      this.data.op = 'CPA';
      this.accion = 'comprar';
      this.stepTexts.titleSuper = 'Confirmar compra';
      this.data.tituloOp = 'Compra';
      this.stepTexts.textSuper =
        'Para ejecutar la operación, verifica los datos ingresados y haz clic en ';
      this.stepTexts.textSuperStrong = 'Aceptar';
    }
    if (this.data.operacion === 'vender') {
      this.data.op = 'VTA';
      this.accion = 'vender';
      this.stepTexts.titleSuper = 'Confirmar venta';
      this.data.tituloOp = 'Venta';
      this.stepTexts.textSuper =
        'Para ejecutar la operación, verifica los datos ingresados y haz clic en ';
      this.stepTexts.textSuperStrong = 'Aceptar';
    }
    if (this.data.operacion === 'Transferencia') {
      this.stepStatus = true;
      this.accion = 'transferencia';
      this.stepTexts.titleSuper = 'Procesando operación';
      this.data.tituloOp = this.data.operacion;
      this.stepTexts.textSuper = '';
      this.stepTexts.textSuperStrong = '';
      this.stepTexts.textStatus =
        'Solicitud de destino de los recursos enviada exitosamente';
      this.data.folio_movimiento = this.data.folio_movimiento;
      this.data.folio_traspaso = this.data.foliotraspaso;
    }
    if (this.series != undefined) {
      this.data.comision = this.series.comision;
      this.data.fecha_anticipacion = this.series.fecha_anticipacion;
      this.data.fecha_ejecucion = this.series.fecha_ejecucion;
      this.data.fecha_solicitud = this.series.fecha_ingreso;
      this.data.fecha_liquidacion = this.series.fecha_liquidacion;
      this.data.impuesto = this.series.impuesto;
      this.data.plazo_liquidacion = this.series.plazo_liquidacion;
      this.data.precio_titulo = this.series.precio_titulo;
      this.forma_pago = await this.getFormasPago(this.contrato, this.data.op);
      await this.getChequeraContrato(this.contrato, this.data.op);
      this.concentradoras = await this.getConcentradoras();
    }

    console.log('data', this.data);

    const importeimprote: any = await this.GetDataImportes();
    const importejson = JSON.parse(importeimprote.data);
    console.log('importejson_data======', importejson.data);
    console.log('importejson_data_data======', importejson.data.data[0]);

    let importe = importejson.data.data[0].importe;
    let titulos = importejson.data.data[0].titulos;

    

    this.data.importe = importe[0] === '' ? 0 : parseFloat(importe[0]);
    this.data.titulos = parseFloat(titulos[0]);

    console.log('importe___', this.data.importe);
    console.log('titukis0¿=?=??', this.data.titulos);

    let res = await this.getIP();
    this.coords.ip = res;
    this.coords.address = await this.getAdressComplete();
    this.loaderTransparent.show(false);
  }

  refreshPage() {
    console.log('terminar');

    let retorno: any = {
      opcion: '',
      destino: 'terminar',
      tipooperacion: this.accion,
    };
    this.dataToConfirm.emit(retorno);
  }

  async GetDataImportes() {
    return new Promise((resolve, reject) => {
      this.collection = '/operacion/importetitulos';
      let verb = 'POST';
      let data = {
        contrato: this.data.contrato,
        acciones_propias_id: this.data.acciones_id,
        operacion: this.data.op,
        titulos: this.data.cantidad_final,
        importe: this.data.cantidad_final,
        tipo: this.data.tipooperacion,
      };

      this.send(data, verb).subscribe(response => {
        resolve(response.data.data);
      });
    });
  }

  //Aceptar
  async Aceptar() {
    this.loaderTransparent.show(true);
    console.log('dataoepracion', this.data);
    const response: any = await this.getGrabarOPComplete();
    console.log(response);
    console.log(response.data.length);
    console.log(response.data);

    if (response.data.length > 0) {
      if (response.data[0].status == '200') {
        this.data.fecha_liquidacion = this.formatDate(
          response.data[0].fecha_liquidacion
        );
        this.data.fecha_ejecucion = this.formatDate(
          response.data[0].fecha_operacion
        );
        this.data.fecha_solicitud = this.formatDate(
          response.data[0].fecha_anticipacion
        );
        this.data.folio_movimiento = response.data[0].folio_movimiento;
        this.data.precio_titulo = parseFloat(this.data.precio_titulo).toFixed(
          6
        );
        this.data.efectivo = this.data.importe;

        this.stepStatus = true;
        this.stepTexts.titleSuper = 'Procesando operación';
        this.stepTexts.textSuper =
          'Una vez que se apruebe la podrás ver reflejada en la sección de ';
        this.stepTexts.textSuperStrong = 'Órdenes del día';
        this.stepTexts.textStatus = 'Solicitud de compra enviada exitosamente';
        this.dataResponse = response.data[0];
        this.generarPDF();
      } else {
        this.notification.show(
          'block',
          '',
          response.data[0].error,
          '',
          'error_sendcode',
          ''
        );
      }
    } else {
      this.notification.show(
        'block',
        '',
        'Verifique la operación con su asesor',
        '',
        'error_sendcode',
        ''
      );
    }

    this.loaderTransparent.show(false);
  }

  async generarPDF() {
    const doc = new jsPDF();
    let array: any[] = [];
    let operacion = this.data.operacion === 'vender' ? 'VENTA' : 'COMPRA';
    let listValue = await this.listItems(this.data.operacion);

    const fontPPath = '/LiraSansCompass-Bold.otf';
    const fontPPathNormal = '/LiraSansCompass-Regular.otf';

    doc.addFileToVFS('LiraSansCompass-Regular.otf', fontPPathNormal);
    doc.addFileToVFS('LiraSansCompass-Bold.otf', fontPPath);

    doc.addFont('LiraSansCompass-Bold.otf', 'lirasansBold', 'normal');
    doc.addFont('LiraSansCompass-Regular.otf', 'lirasansRegular', 'normal');

    doc.addImage(this.img, 'JPEG', 13, 10, 50, 15, '', 'FAST'),
      doc.setFillColor(51, 180, 255);
    doc.setFont('lirasansBold', 'normal', 700);
    doc.setFontSize(16);
    doc.setFont('lirasansRegular', 'normal', 700);
    doc.text('COMPROBANTE DE OPERACIÓN - ' + operacion, 16, 43);
    const fontList = doc.getFontList();

    const styles = {
      font: 'lirasansRegular',
      fontStyle: 'normal',
      fontSize: 13, // Tamaño de fuente para todas las celdas
    };

    autoTable(doc, {
      body: listValue,
      theme: 'plain',
      columnStyles: {
        0: { cellWidth: 80, textColor: [30, 30, 30] },
        1: { cellWidth: 80, textColor: [77, 77, 77] },
      },
      styles: { fontSize: 13 },
      startY: 55,
    });
    // Generar el PDF
    let pdfData = doc.output('arraybuffer'); // Obtener los datos binarios del PDF
    // Convertir los datos binarios a Base64
    let base64PDF = await this.bufferToBase64(pdfData);
    // La variable base64PDF ahora contiene la representación en Base64 del PDF generado
    let resp: any = await this.uploadPDF(base64PDF);

    if (resp.status === 200) {
      window.open(resp.data.archivo);
    } else {
      this.notification.show(
        'block',
        '',
        resp.data.error,
        '',
        'error_sendcode',
        ''
      );
    }
  }

  uploadPDF(pdf) {
    return new Promise((resolve, reject) => {
      this.collection = '/operacion/uploadpdf';
      let verb = 'POST';
      let data = {
        folio: this.data.folio_movimiento,
        pdf: pdf,
      };
      this.send(data, verb).subscribe(response => {
        resolve(response);
      });
    });
  }

  // Función para convertir datos binarios a Base64
  async bufferToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  recursosDestino() {
    this.data.opcion = 'compven';
    this.data.destino = 'recursos';
    this.dataToConfirm.emit(this.data);
  }
  ///cancelar o regrerdar
  BacktoPage() {
    console.log('back');

    let retorno: any = {
      opcion: '',
      destino: 'visualizar',
      tipooperacion: this.accion,
    };
    this.dataToConfirm.emit(retorno);
  }

  BacktoPageCancel() {
    let retorno: any = {
      opcion: '',
      destino: 'cancelar',
      tipooperacion: this.accion,
    };
    console.log('confirmar', retorno);
    this.dataToConfirm.emit(retorno);
  }

  async getGrabarOPComplete() {
    return new Promise((resolve, reject) => {
      this.collection = '/operacion/grabaroperacion';
      let verb = 'POST';
      let data = {
        contrato: this.data.contrato,
        acciones_propias_id: this.data.acciones_id,
        operacion: this.data.op,
        //serie: this.data.serie,
        liq_forma_pago: this.data.operacion === 'vender' ? 6 : 2, // opcion i 4 => liquidacion por indeval, fija, en venta], [para comprar probar con vacio o concentradora id 1.
        importe: this.data.importe,
        titulos: this.data.titulos,
        operacionpor:
          this.data.tipooperacion === 'monto' ? 'Importe' : 'Titulos',
        observaciones: 'no hay',
        liq_banco_cuenta: this.data.operacion === 'vender' ? '' : '', //
        ip: this.coords.ip,
        latitud: this.coords.latitud,
        longitud: this.coords.longitud,
        cp: this.coords.address.data.postcode,
        ciudad: this.coords.address.data.city,
        estado: this.coords.address.data.state,
        pais: this.coords.address.data.country,
        calle: this.coords.address.data.road,
        fechaInicial: this.startDate,
        fechaFinal: this.startDate,
      };
      console.log('datagrabaroperacion', data);
      this.send(data, verb).subscribe(response => {
        resolve(response['data']);
      });
    });
  }

  async getAdressComplete() {
    return new Promise((resolve, reject) => {
      this.collection = '/geolocalizacion';
      let verb = 'POST';
      let data = {
        latitud: this.coords.latitud,
        longitud: this.coords.longitud,
      };
      this.send(data, verb).subscribe(response => {
        if (response['data']) {
          resolve(response.data.data);
        } else {
          resolve({ data: 'sin resultado' });
        }
      });
    });
  }

  ///EJECUCIONES DE PROCESOS ANTES DE INSERTAR

  async getConcentradoras() {
    return new Promise((resolve, reject) => {
      this.collection = '/operacion/concentradoras';
      let verb = 'POST';
      let data = {
        contrato: this.contrato,
      };
      this.send(data, verb).subscribe(response => {
        let resp: any = response.data;
        if (resp.data.length > 0) {
          resolve(resp);
        } else {
          resp = [];
          reject(resp);
        }
      });
    });
  }

  //LISTA DE ITEMS A IMPRIMIR
  listItems(operacion) {
    let response;

    let solicitado =
      this.data.tipooperacion === 'monto' ? 'Importe solicitado' : 'Solicitado';
    let solvalue =
      this.data.tipooperacion === 'monto'
        ? '$ ' + this.data.cantidad
        : this.data.cantidad + ' Títulos';

    if (operacion === 'comprar') {
      response = [
        {
          text: 'Operación',
          value: 'COMPRA',
        },
        {
          text: 'Folio',
          value: this.data.folio_movimiento,
        },
        {
          text: 'Fondo',
          value: this.data.fondo,
        },
        {
          text: 'Serie',
          value: this.data.serie,
        },
        {
          text: 'Fecha de solicitud',
          value: this.data.fecha_solicitud,
        },
        {
          text: 'Fecha de ejecución',
          value: this.data.fecha_ejecucion,
        },
        {
          text: 'Fecha de liquidación',
          value: this.data.fecha_liquidacion,
        },
        {
          text: 'Precio por título',
          value: '$ ' + this.data.precio_titulo,
        },
        {
          text: 'Títulos',
          value: this.data.titulos,
        },
        {
          text: solicitado,
          value: solvalue,
        },
        {
          text: 'Importe asignado',
          value: '$ ' + this.data.importe,
        },
      ];
    }
    if (operacion === 'vender') {
      response = [
        {
          text: 'Operación',
          value: 'VENTA',
        },
        {
          text: 'Folio',
          value: this.data.folio_movimiento,
        },
        {
          text: 'Fondo',
          value: this.data.fondo,
        },
        {
          text: 'Serie',
          value: this.data.serie,
        },
        {
          text: 'Fecha de solicitud',
          value: this.data.fecha_solicitud,
        },
        {
          text: 'Fecha de ejecución',
          value: this.data.fecha_ejecucion,
        },
        {
          text: 'Fecha de liquidación',
          value: this.data.fecha_liquidacion,
        },
        {
          text: 'Precio por título',
          value: '$ ' + this.data.precio_titulo,
        },
        {
          text: 'Títulos',
          value: this.data.titulos,
        },
        {
          text: solicitado,
          value: solvalue,
        },
        {
          text: 'Importe asignado',
          value: '$ ' + this.data.importe,
        },
      ];
    }

    return response;
  }

  private formatDate(date) {
    let dateFormat = new Date(date);

    let day = ('0' + dateFormat.getUTCDate().toString()).slice(-2);
    let mesTmp = dateFormat.getUTCMonth();
    let month = ('0' + (mesTmp + 1).toString()).slice(-2);
    let year = dateFormat.getFullYear().toString().toString();

    return day + '/' + month + '/' + year;
  }
}
