import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseService } from 'src/app/base/base.service';

@Component({
  selector: 'app-contracts',
  templateUrl: './contractList.component.html',
  styleUrls: ['./contractList.component.scss'],
})
export class ContractsListComponent extends BaseService implements OnInit {
  bussiness: any = [];

  contracts: any = [];

  selectedBussines = { id: '', name: '' };
  selectedContract = '';
  date: any;
  dateEnd: any;

  @Input() showDateRange: Boolean = false;
  @Input() showArrow: Boolean;
  @Input() title: string = 'Tus contratos';

  minDay: string;
  maxDay: string;

  dateRange = false;

  public disabledButtton = false;

  @Output() valueResponse: EventEmitter<{}> = new EventEmitter();

  ngOnInit(): void {
    this.getDates();

    this.loaderTransparent.show(true);
    let email = this.session.getEmail();
    this.getAll('/contract?email=' + email).subscribe(response => {
      if (response.data.companies.length > 0) {
        this.bussiness = response.data.companies.map(item => ({
          contracts: item.contracts,
          name: item.name,
          id: item.id + this.getRandomInt().toString(),
        }));

        /** If have selected contract, here set global contract variables */
        let bussinesId = localStorage.getItem('bussiness');
        let contractId = localStorage.getItem('contract');

        this.selectedBussines = this.bussiness.find(
          element => element.id == bussinesId
        );
        console.log(this.selectedBussines);
        this.selectedContract = this.selectedBussines['contracts'].find(
          element => element == contractId
        );
        console.log(this.selectedContract);

        this.contracts = this.selectedBussines['contracts'];
        console.log(contractId);
        if (contractId === undefined) {
          console.log(this.selectedBussines['contracts'][0]);
          contractId = this.selectedBussines['contracts'][0];
        }

        if (this.showDateRange === false) {
          this.date = this.date;
          this.dateEnd = localStorage.getItem('date');
        } else {
          if (localStorage.getItem('date')) {
            this.date = localStorage.getItem('date');
            this.dateEnd = localStorage.getItem('date');
          }
        }

        this.sendData();
        setTimeout(() => {
          this.loaderTransparent.show(false);
        }, 4000);
      }
    });
  }

  cont = 0;
  getRandomInt() {
    //TODO borrar cuando los de compass remieden sus id en los contratos
    this.cont = this.cont + 1;
    return this.cont;
  }

  sendData(): void {
    localStorage.setItem('bussiness', this.selectedBussines.id);
    localStorage.setItem('contract', this.selectedContract);
    localStorage.setItem('date', this.date);

    this.disabledButtton = true;

    this.valueResponse.emit({
      bussines: this.selectedBussines,
      contract: this.selectedContract,
      date: this.date,
      dateEnd: this.dateEnd,
    });
  }

  onChangeBusiness(event): void {
    let index = event.target.value;
    this.contracts = this.bussiness[index].contracts;
    this.selectedBussines = this.bussiness[index];
    this.selectedContract = '';
    this.disabledButtton = false;
  }

  onChangeContract(event): void {
    let index = event.target.value;
    this.selectedContract = this.contracts[index];
    this.disabledButtton = false;
  }

  public onChangeDate() {
    this.disabledButtton = false;
  }

  return(): void {
    this.router.navigate(['/portal-clientes/contratos']);
  }

  private getDates(): void {
    let data = new Date();

    let day = ('0' + data.getDate().toString()).slice(-2);
    let month = ('0' + (data.getMonth() + 1)).slice(-2);
    let year = data.getFullYear().toString();
    let yearMin = (data.getFullYear() - 2).toString();

    this.date = year + '-' + month + '-' + day;
    this.dateEnd = year + '-' + month + '-' + day;
    this.minDay = yearMin + '-' + month + '-' + day;
    this.maxDay = year + '-' + month + '-' + day;
    //console.log(this.date);
  }
}
