import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/app/base/base.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent extends BaseService implements OnInit {
  /**
   * @property {FormGroup} Form get only code
   * @public
   */
  public myCodeForm: FormGroup;
  /**
   * @property {FormGroup} Form get new password and confirm password
   * @public
   */
  public myPasswordForm: FormGroup;

  /**
   * @property {boolean}  Show or hide email view
   * @public
   */
  public showEmail: boolean = true;
  /**
   * @property {boolean}  Show or hide 2FA code view
   * @public
   */
  public showCode: boolean = false;
  /**
   * @property {boolean} Show or hide new password view
   * @public
   */
  public showNewpass: boolean = false;
  /**
   * @property {String} Icon type/name
   * @public
   */
  public eyeIcon = 'visibility_off';
  /**
   * @property {String} Icon type/name
   * @public
   */
  public eyeIconConfirm = 'visibility_off';
  /**
   * @property {String} Okta user Id.
   * @private
   */
  private okta_user_id = '';

  ngOnInit(): void {
    this.myForm = this.fb.group({
      email: [
        '',
        Validators.compose([Validators.required, Validators.minLength(10)]),
      ],
    });

    this.myCodeForm = this.fb.group({
      code: ['', Validators.required],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(8)]),
      ],
      repeatePassword: [
        '',
        Validators.compose([Validators.required, Validators.minLength(8)]),
      ],
    });
  }

  /**
   * @description Send 2FA code
   * @returns void
   */
  sendCode(): void {
    this.collection = '/auth/code';
    let verb = 'POST';
    this.loader.show(true);
    let data = {
      user: this.myForm.value.email,
    };
    this.sendWithoutHeaders(data, verb).subscribe(response => {
      if (response.message == 'Success') {
        this.notification.show('block', '', 'Código enviado.', '', 'check', '');
      }
      this.loader.show(false);
      this.showEmail = false;
      this.showCode = true;
    });
  }

  /**
   * @description Send user email
   * @returns void
   */
  onSubmitEmail(form: FormGroup) {
    this.collection = '/auth/forgot';
    let verb = 'POST';
    this.loader.show(true);
    let data = {
      user: form.value.email,
    };
    this.sendWithoutHeaders(data, verb).subscribe(response => {
      console.log(response);
      if (response.message == 'Success') {
        this.notification.show('block', '', 'Código enviado.', '', 'check', '');
        this.okta_user_id = response.data.data[0].identities[0].user_id;
      }
      this.showEmail = false;
      this.showCode = true;
      
      setTimeout(() => {
        this.myCodeForm.reset();
        this.loader.show(false);
      }, 750);
    });
  }

  /**
   * @description Send 2FA code new password and user
   * @returns void
   */
  onSubmitCode(form: FormGroup) {
    this.collection = '/auth/forgot/confirm';
    let verb = 'POST';
    this.loader.show(true);
    let data = {
      code: form.value.code.toString(),
      password: form.value.password,
      user: this.okta_user_id,
      email: this.myForm.value.email,
    };
    this.sendWithoutHeaders(data, verb).subscribe(response => {
      if (response.message == 'Success') {
        this.notification.show(
          'block',
          '',
          'Cambio de contraseña exitoso.',
          'Iniciar sesión',
          'check',
          '/login'
        );
      }
      this.loader.show(false);
    });
  }
  /**
   * @description Can change input typefrom password to text
   * @returns void
   */
  public showPass() {
    this.eyeIcon == 'visibility_off'
      ? (this.eyeIcon = 'visibility')
      : (this.eyeIcon = 'visibility_off');

    let inputPass = document.getElementById('password')!;

    if (inputPass.getAttribute('type') === 'password') {
      inputPass.setAttribute('type', 'text');
    } else {
      inputPass.setAttribute('type', 'password');
    }
  }
  /**
   * @description Can change input typefrom passwordConfirm to text
   * @returns void
   */
  public showPassConfirm() {
    this.eyeIconConfirm == 'visibility_off'
      ? (this.eyeIconConfirm = 'visibility')
      : (this.eyeIconConfirm = 'visibility_off');

    let inputPass = document.getElementById('passwordConfirm')!;

    if (inputPass.getAttribute('type') === 'password') {
      inputPass.setAttribute('type', 'text');
    } else {
      inputPass.setAttribute('type', 'password');
    }
  }
}
