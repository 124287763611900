<app-breadcum [data]="dataBreadcum"></app-breadcum>

<app-contracts
  [showArrow]="true"
  [title]="title"
  (valueResponse)="getContactData($event)"></app-contracts>

<ng-container *ngIf="startDate">
  <div class="fadeIn row ms-4 me-4">
    <!-- VALOR Y FLUJOS -->
    <div class="col-xl-4 col-lg-6 ps-0 pe-0 pe-sm-0 pe-lg-2">
      <!-- VALOR -->
      <div class="col-lg-12">
        <div class="card">
          <div class="card-head d-flex">
            <div class="icon-card">
              <img
                src="assets/icons/i-worth.svg"
                class="big"
                alt="Valor de portafolio" />
            </div>
            <span class="weight-400">Valor de portafolio </span
            ><span class="mobil">&nbsp; {{ idContract }}</span>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="scroll-table">
                <table class="table table-borderless valorTable">
                  <thead>
                    <tr>
                      <th>Valor</th>
                      <th>Saldo en efectivo</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of porfolio">
                      <td class="text-start">
                        ${{ item.value.toString() | number : '1.2-2' }}
                      </td>
                      <td class="text-start">
                        ${{ item.cashBalance.toString() | number : '1.2-2' }}
                      </td>
                      <td class="text-start">
                        ${{ item.total.toString() | number : '1.2-2' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="withoutData" *ngIf="porfolio.length === 0">
                  <span>No hay datos disponibles </span>
                </div>
              </div>
              <div class="d-flex justify-content-end ver-mas mt-2">
                <a routerLink="./{{ routes.VALOR_PORTAFOLIO }}">
                  <span>Ver más</span>
                  <span class="material-symbols-outlined arrow">
                    chevron_right
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- FLUJOS -->
      <div class="col-lg-12 mt-3 mb-4">
        <div class="card">
          <div class="card-head d-flex">
            <div class="icon-card">
              <img src="assets/icons/i-flow.svg" class="small" alt="Flujos" />
            </div>
            <span class="weight-400">Flujos </span>
            <span class="mobil">&nbsp; {{ idContract }}</span>
          </div>
          <div class="card-body">
            <div class="row">
              <label class="sub-title mb-1">Total</label>
              <div class="scroll-table">
                <table class="table table-borderless flowTable">
                  <tbody>
                    <tr *ngFor="let item of flow">
                      <td class="flowTitle">{{ item.concept | titlecase }}</td>
                      <td>
                        <div
                          class="d-flex flex-row justify-content-between flowValue">
                          <div class="ms-auto">$</div>
                          <div>
                            {{ item.total.toString() | number : '1.2-2' }}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="withoutData" *ngIf="flow.length === 0">
                <span>No hay datos disponibles </span>
              </div>
              <div class="d-flex justify-content-end ver-mas mt-2">
                <a routerLink="./{{ routes.FLUJO }}">
                  <span>Ver más</span>
                  <span class="material-symbols-outlined arrow">
                    chevron_right
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- POSICION -->
    <div
      class="col-xl-4 col-lg-6 mt-lg-0 mt-md-0 mb-4 ps-0 pe-0 pe-lg-0 ps-lg-2 pe-xl-2">
      <div class="card">
        <div class="card-head d-flex">
          <div class="icon-card">
            <img
              src="assets/icons/i-pie_chart.svg"
              class="big"
              alt="Posición de valores" />
          </div>
          <span class="weight-400">Posición</span>
          <span class="mobil">&nbsp; {{ idContract }}</span>
        </div>
        <div class="">
          <div class="card-body">
            <div class="row">
              <div class="d-flex">
                <div class="col-12">
                  <ng-container *ngIf="showChart">
                    <canvas
                      baseChart
                      [data]="pieChartData"
                      [type]="pieChartType"
                      [options]="pieChartOptions">
                    </canvas>
                  </ng-container>
                </div>
              </div>

              <div class="table-chart">
                <label class="sub-title mt-5 mb-1">Totales</label>
                <div class="scroll-table">
                  <table class="table table-borderless totales-pos">
                    <tbody>
                      <ng-container *ngIf="showPositionTable">
                        <tr>
                          <td>Monto</td>
                          <td class="text-end">
                            ${{
                              posicions.amount.toString() | number : '1.2-2'
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Costo total</td>
                          <td class="text-end">
                            ${{
                              posicions.totalCost.toString() | number : '1.2-2'
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Plus / Minusvalía</td>
                          <td
                            class="text-end"
                            [ngClass]="{
                              ' red': posicions.value < 0,
                              green: posicions.value > 0
                            }">
                            ${{ posicions.value.toString() | number : '1.2-2' }}
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
                <div class="withoutData" *ngIf="!showPositionTable">
                  <span>No hay datos disponibles </span>
                </div>
                <div class="d-flex justify-content-end ver-mas mt-2">
                  <a routerLink="./{{ routes.POSICION }}">
                    <span>Ver más</span>
                    <span class="material-symbols-outlined arrow">
                      chevron_right
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MOVIMIENTOS  -->
    <div
      class="col-xl-4 col-lg-12 mt-xl-0 mt-lg-4 mb-lg-5 mb-mov pe-0 ps-0 ps-xl-2">
      <div class="card">
        <div class="card-head d-flex">
          <div class="icon-card">
            <img
              src="assets/icons/i-movement.svg"
              class="small"
              alt="Movimientos" />
          </div>
          <span class="weight-400">Movimientos</span>
          <span class="mobil">&nbsp; {{ idContract }}</span>
        </div>
        <label class="sub-title mt-4" for="Disponible"
          >Últimos movimientos (5 días)</label
        >
        <label class="sub-title mov-dates" for="Disponible">{{
          rangeMovements
        }}</label>
        <ng-container *ngIf="movements.length > 0">
          <div class="scroll-table">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th>
                    <div class="d-flex flex-column">
                      <div>Fecha de</div>
                      <div>ingreso</div>
                    </div>
                  </th>
                  <th>Operación</th>
                  <th>Fondo</th>
                  <th>Serie</th>
                  <th>Títulos</th>
                  <th>Precio</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of movements">
                  <td class="text-center">{{ item.entryDate }}</td>
                  <td>{{ item.operation }}</td>
                  <td>{{ item.fund }}</td>
                  <td>{{ item.serie }}</td>
                  <td>{{ item.titles.toString() | number : '1.0-0' }}</td>
                  <td>
                    $ {{ item.priceFormartZeros.toString() | number : '1.6-6' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
        <div class="withoutData" *ngIf="movements.length === 0">
          <span>No hay movimientos</span>
        </div>
        <div class="d-flex justify-content-end ver-mas mt-2">
          <a routerLink="./{{ routes.MOVIMIENTOS }}">
            <span>Ver más</span>
            <span class="material-symbols-outlined arrow"> chevron_right </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
