<div class="bg-white p-4 d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center mb-4">
        <h1 class="spanAlternativeTitle m-0">Beneficiarios
        </h1>
        <button class="btn-alternative" (click)="export()">
            <img src="assets/icons/i-export.svg" alt="Exportar">
            Exportar
        </button>
    </div>
    <table class="dashboard">
        <thead>
            <th *ngFor="let title of headersTable">
                <div>
                    {{title.header}}
                    <img *ngIf="title.valor != ''" (click)="sortTable(title.valor)" src="assets/icons/i-tabla-sort.svg"
                        alt="ordenar">
                </div>
            </th>
        </thead>
        <tbody>
            <tr *ngFor="let person of data_to_show">
                <td>{{ person.cliente}}</td>
                <td>
                    <div class="status-container">
                        <div class="status" [ngClass]="{'about-to-expire': person.estatus == 'Por vencer',
                                'updated': person.estatus === 'Actualizado', 
                                'expired': person.estatus === 'Vencido', 
                                'notified': person.estatus === 'Notificado', 
                                'need-sign': person.estatus === 'Espera firma'} "></div>
                        <p class="m-0">{{ person.estatus }}</p>
                    </div>
                </td>
                <td>{{ person.ultima_actualizacion }}</td>
                <td>{{ person.type }}</td>
                <td>{{ person.lastNotification }}</td>
                <td>
                    <div class="d-flex flex-row align-items-center w-100 justify-content-evenly second-blue pdf"
                        (click)="openPdf(person)">
                        <img src="assets/icons/i-pdf.svg" alt="PDF">
                        <span>
                            Cliente_PDF
                        </span>
                    </div>
                </td>
            </tr>

        </tbody>
    </table>
    <!--     <p class="spanAlternativeSubTitle mb-2 mt-2">Filas a mostrar</p>
    <select name="paginado" id="paged" class="select_primary col-2 ms-1" (ngModelChange)="onChange($event)"
        [(ngModel)]="valor">
        <option value="0" disabled selected>Filas</option>
        <option *ngFor="let option of selectOptions" [ngValue]="option.id">
            {{ option.value }}
        </option>
    </select>
    <div class="col-3">
        <p class="spanAlternativeSubTitle mb-2">Nombre cliente</p>
        <input class="input_primary f-14" type="text" [ngModel]="search_by_name" (ngModelChange)="filterByName($event)"
            placeholder="Ingresar nombre" />
    </div> -->
    <div class="d-flex flex-row align-items-center gap-3 align-self-end" *ngIf="total_pages>1">
        <p class="m-0 spanAlternativeSubTitle">Páginas</p>
        <button class="btn btn-primary" (click)="arrowStart()">
            <img src="assets/icons/i-arrow-left-white.svg" alt="Flecha izquierda">
        </button>
        <button class="btn" (click)="arrowPrevious()">
            <img src="assets/icons/i-arrow-left.svg" alt="Flecha izquierda">
        </button>
        <p class="m-0 spanAlternativeSubTitle"><span>{{page}}</span> de <span>{{total_pages}}</span></p>
        <button class="btn" (click)="arrowNext()">
            <img src="assets/icons/i-arrow-right.svg" alt="Flecha derecha">
        </button>
        <button class="btn btn-primary" (click)="arrowEnd()">
            <img src="assets/icons/i-arrow-right-white.svg" alt="Flecha derecha">
        </button>
    </div>
</div>