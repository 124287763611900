export const appRoutesNames = {
  HOME: 'home',
  PORTAL_CLIENTES: 'portal-clientes',
  BENEFICIARIOS: 'beneficiarios',
  DESCARGAS: 'descargas',
  ACTUALIZAR_DATOS: 'actualizar-datos',
  CERRAR_SESION: 'cerrar sesión',
  LOGIN: 'login',
  LOGIN_TWOFACTOR: 'login-two-factor',
  AJUSTES: 'ajustes',
  CONTRATOS: 'contratos',
  PRINCIPAL: 'principal',
  VALOR_PORTAFOLIO: 'valor-portafolio',
  POSICION: 'posicion',
  FLUJO: 'flujo',
  MOVIMIENTOS: 'movimientos',
  SINGUP: 'singup',
  RECUPERAR: 'recuperar-contrasena',
  TABLA_ADMIN: 'tabla-admin',
  DASHBOARD: 'dashboard',
  MANUALES: 'manuales',
  DOCUMENTOS: 'documentos',
  CG_ALERNATIVES: 'cg-alternatives',
  CG_DYNAMIC_REPORT: 'cg-dynamic-report',
  NETX: 'NetX',
  TRANSACCION: 'transaccion',
  FONDOS: 'caracteristicas',
};
