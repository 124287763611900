<div class="fadeIn">
  <div class="row logo_phone">
    <img
      class="logo"
      src="/assets/images/logo-blanco.png"
      alt="logo-compasss-blanco"
      height="90px"
      width="250px" />
  </div>
  <div class="public-pages d-flex justify-content-center align-items-center">
    <div class="d-flex w-100 justify-content-center">
      <div
        class="fadeIn card card-login col-xxl-3 col-xl-4 col-lg-4 col-md-8 col-10">
        <ng-container>
          <h1>Ingresar código</h1>
          <form [formGroup]="myForm" (ngSubmit)="sendCode(myForm)">
            <label for="mail">Código enviado al correo</label>
            <input
              type="text"
              formControlName="code"
              class="form-control mb-3" />

            <div class="d-flex buttons_code_public">
              <button
                type="button"
                class="btn btn-alternative w-45 mr-3"
                (click)="onSubmitLogin()">
                Reenviar código
              </button>
              <button
                type="submit"
                class="btn btn-primary w-45 ml-3"
                [disabled]="myForm.invalid">
                Continuar
              </button>
            </div>
            <div
              class="d-flex justify-content-center mt-4 margin-bottom-forgot mb-2">
              <a class="pointer" (click)="backToLogin()">Volver </a>
            </div>
          </form>
        </ng-container>
      </div>
    </div>
  </div>
</div>
