<ng-container>
  <div class="contt">
    <ul class="breadcrumb">
        <ng-container *ngFor="let item of breadcum">
            <li (click)="redirectBreadcum(item)">{{ item }}</li>
        </ng-container>
    </ul>

    <span class="spanSubTitle f-20">{{ title }} </span>
    <div class="d-flex align-items-center justify-content-between">
      <span
        class="spanAlternativeSubTitle f-16 mt-3"
        [innerHTML]="subtitle"></span>
      <a class="orange-link mt-3" *ngIf="icon" (click)="redirecTo()"
        ><object
          class="me-2"
          data="assets/icons/i-info-orange.svg"
          type=""></object
        >Caracteristicas fondos</a
      >
    </div>
  </div>
</ng-container>
