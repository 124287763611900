/**
 * @autor Eduardo Torres <eduardo.tg@ix.agency>
 */
import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { BaseService } from '../base/base.service';
import { appRoutesNames } from '../app-routing/app.routes.names';

@Component({
  selector: 'app-beneficiarios',
  templateUrl: './beneficiarios.component.html',
  styleUrls: ['./beneficiarios.component.scss'],
})
export class BeneficiariosComponent extends BaseService implements OnInit {
  ngOnInit(): void {
    if (localStorage.getItem('userName') === 'true') {
      this.router.navigate(['dashboard/' + appRoutesNames.TABLA_ADMIN]);
    }
    this.sendDataToBreadcum(['Home', 'Beneficiarios']);
    this.myForm = this.fb.group({
      subforms: this.fb.array([]),
    });
  }

  public subformReady(subform: FormGroup) {
    this.subforms.push(subform);
  }

  get subforms(): FormArray {
    return this.myForm.get('subforms') as FormArray;
  }

  public sendData() {
    this.loader.show(true);
    this.collection = '/beneficiarios/send-document-data';
    let forms = this.myForm.value.subforms;
    let email = this.session.getEmail();

    let obj = {
      ...forms[0],
      ...forms[1],
      ...forms[2],
      email,
      nombreDeUsuario: localStorage.getItem('userName'),
    };

    let datePart = obj.sign_date.match(/\d+/g),
      year = datePart[0].substring(2),
      month = datePart[1],
      day = datePart[2];

    obj = { ...obj, day, month, year };

    if (obj.society === 'other') {
      obj.society = obj.society_other;
    }

    this.send(obj).subscribe((response: string) => {
      if (typeof response === 'string') {
        window.open(response);
      }
      this.loader.show(false);
    });
  }

  private sendDataToBreadcum(breadcum: string[]) {
    this.titleContainerService.show(
      true,
      breadcum,
      'Formulario de beneficiarios',
      'En este formulario debes, lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu.'
    );
  }
}
