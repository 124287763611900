import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AlternativeSidebarComponent } from './alternative-sidebar/alternative-sidebar.component';
import { UserOptionsComponent } from './user-options/user-options.component';
import { DataService } from '../common/services/menu.service';

// Local Components
@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [
    SidebarComponent,
    NavbarComponent,
    AlternativeSidebarComponent,
    UserOptionsComponent,
  ],
  exports: [SidebarComponent, NavbarComponent, AlternativeSidebarComponent],
  providers: [DataService],
})
export class TemplateModule {}
