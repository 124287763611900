<aside
  class="sidebar"
  [ngClass]="{ ' bigger': !isOpen, sidebar_phone: isOpen }">
  <nav class="main-menu" id="menu">
    <ul *ngIf="menu_items.length > 0" class="nav_items">
      <ng-container *ngFor="let item of menu_items">
        <li
          class="tooltipp"
          [ngClass]="{
            selected: item.isSelected,
            tooltipp: item.tooltip,
            'item-selected': item.id === iditem,
            principal: item.principal
          }"
          (click)="routerToPath(item.route, item)">
          <a class="h-100">
            <div class="d-flex" [ngClass]="{ 'more-left': item.itemsubmenu }">
              <img src="{{ item.icon }}" alt="{{ item.title }}" />
              <span class="nav-text title_menu"> {{ item.title }}</span>
              <span class="tooltiptextt" *ngIf="item.tooltip"
                >Al acceder a este enlace, serás redirigido fuera de esta
                página</span
              >
            </div>
          </a>
        </li>
      </ng-container>
    </ul>
  </nav>
</aside>
<!-- 
<div class="d-flex flex-row">
  <nav class="main-menu" id="menu">
    <ul>
      <li>
        <a class="h-100" (click)="returnHome()">
          <div class="d-flex justify-content-between w-212">
            <img src="assets/icons/i-home.svg" alt="Resumen" />
            <span class="nav-text"> Inicio </span>
            <img src="assets/icons/i-arrow-right.svg" alt="Inicio" />
          </div>
        </a>
      </li>
      <li class="has-subnav">
        <a
          class="h-100"
          routerLink="./{{ routes.CONTRATOS }}"
          routerLinkActive="active">
          <div class="d-flex justify-content-between" (click)="closeMenu()">
            <img src="assets/icons/i-article.svg" alt="Contratos" />
            <span class="nav-text"> Contratos </span>
            <img src="assets/icons/i-arrow-right.svg" alt="Contratos" />
          </div>
        </a>
      </li>
      <li class="has-subnav">
        <a
          class="h-100"
          routerLink="./{{ routes.DESCARGAS }}"
          routerLinkActive="active">
          <div class="d-flex justify-content-between" (click)="closeMenu()">
            <img src="assets/icons/i-download.svg" alt="Descargas" />
            <span class="nav-text"> Descargas </span>
            <img src="assets/icons/i-arrow-right.svg" alt="Descargas" />
          </div>
        </a>
      </li>
    </ul>
  </nav>

  <div (click)="openMenu()">
    <img
      src="assets/images/hamburguer.png"
      class="hamburguer"
      alt=""
      id="hamburguer" />
  </div>
</div> -->
