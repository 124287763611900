import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class LoaderService {
  /**
   * @property {BehaviorSubject} A new instance from Behavior Subject
   * @private
   */
  private messageSource = new BehaviorSubject<any>({
    showLoader: false,
  });
  /**
   * @property {Observable} Observable object
   * @public
   */
  currentMessage = this.messageSource.asObservable();
  constructor() {}
  /**
   * @description Insert a new status in the chain
   * @param showLoader {boolean} Show or Hide loader
   */
  show(showLoader: boolean) {
    this.messageSource.next({
      showLoader: showLoader,
    });
  }
}
