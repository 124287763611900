import { Component, Input, OnInit } from '@angular/core';
import { BaseService } from 'src/app/base/base.service';

@Component({
  selector: 'app-proceso',
  templateUrl: './proceso.component.html',
  styleUrls: ['./proceso.component.scss']
})
export class ProcesoComponent extends BaseService implements OnInit {
  @Input() data:any = [];
  @Input() accion:string = '';
  async ngOnInit(): Promise<void> {
    console.log(this.data);

    this.data.cantidadtexto = this.data.tipooperacion === 'monto' ? '$ ' + this.data.cantidad : this.data.cantidad + ' Títulos';

    /* if (this.data.operacion != 'Transferencia') {
      let response = await this.getDataOperation(this.data);
    } */
  
  }

}
