/**
 * @author       Eduardo Leonardo Torres Guevara <eduardo.tg@ix.agency>
 * @copyright    2022 IX Agency.
 * @lastmodifiedDate 30.08.2023 11:57
 */
import { Directive, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { appRoutesNames } from 'src/app/app-routing/app.routes.names';

import { LoaderService } from '../common/loader/loader.service';
import { LoaderTransparentService } from '../common/loaderTransparent/loaderTransparent.service';
import { NotificationService } from '../common/notification/notification.service';
import { RequestService } from '../request/request.service';
import { SessionService } from '../util/session/session.service';
import { TitleContainerService } from '../common/title-container/title-container.service';
import { AuthService } from '@auth0/auth0-angular';
import { DataService } from '../common/services/menu.service';
import { ModalService } from './../common/modal/modal.service';
import { ClosewindowsService } from '../common/services/closewindows.service';
import * as CryptoJS from 'crypto-js';
import { Idle } from 'idlejs';

// Global service
@Directive()
export class BaseService {
  /**
   * @property {string} Collection name to use from every method in the request
   */
  collection: string = ''; /**
 /**
  * @property {appRoutesNames} all routes name
  */
  routes = appRoutesNames;
  /**
   * @property {FormGroup} generic form group
   */
  public myForm: FormGroup;
  /**
   *
   * @param requestService Reerence to Request Service Class
   * @param session Reference to Session Service Class
   */
  /**
   *
   * @param requestService Reerence to Request Service Class
   * @param session Reference to Session Service Class
   */
  public ubicacionActual: any;
  dataBreadcum: any = [];
  public dataResponse: any = [];

  constructor(
    private requestService: RequestService,
    public router: Router,
    public fb: FormBuilder,
    protected notification: NotificationService,
    protected loader: LoaderService,
    protected loaderTransparent: LoaderTransparentService,
    public session: SessionService,
    public titleContainerService: TitleContainerService,
    public auth: AuthService,
    public dataService: DataService,
    public modal: ModalService,
    public windowListenerService: ClosewindowsService,
    public renderer: Renderer2,
    public activateRouter: ActivatedRoute
  ) {}
  /**
   * @description Allow send a data
   * @param model {object} Object that represent a new/exist record
   * @param method {string} Can be a POST or PATCH method
   * @param action {string} Parameter to represent a optional function available in the service
   * @returns \{{{Observable}}\} Return an observable for subscribing
   */
  send(model = {}, method = 'POST', action = ''): Observable<any> {
    action = `${this.collection}${action}`;
    return this.requestService.execute(action, model, method).pipe(
      tap(response => {
        this.requestService.updateHeaders('Content-Type', 'application/json');
        //this.requestService.updateHeaders('Access-Control-Allow-Origin', '*');
        this.requestService.removeHeader('enctype');
      })
    );
  }

  /**
   * @description Allow send a data
   * @param model {object} Object that represent a new/exist record
   * @param method {string} Can be a POST or PATCH method
   * @param action {string} Parameter to represent a optional function available in the service
   * @returns \{{{Observable}}\} Return an observable for subscribing
   */
  sendWithoutHeaders(
    model = {},
    method = 'POST',
    action = ''
  ): Observable<any> {
    action = `${this.collection}${action}`;
    this.requestService.updateHeaders('Content-Type', 'application/json');
    return this.requestService.execute(action, model, method, true).pipe(
      tap(response => {
        this.requestService.updateHeaders('Content-Type', 'application/json');
        this.requestService.removeHeader('enctype');
      })
    );
  }

  /**
   * @description Get one single element by ID
   * @param id {string} Id from the element to recover
   * @param action {string} Parameter to represent a optional function available in the service
   * @param filter {object} Options available for adding filters
   * @returns \{{{Observable}}\} Return an observable for subscribing
   */
  get(id: string, action = '', responseType?): Observable<any> {
    action = `${this.collection}/${id}${action}`;
    /*     if (responseType != undefined) {
      this.requestService.updateHeaders('Content-Type', 'application/pdf');
      this.requestService.responseType = 'blob' as 'blob';
    } */
    return this.requestService.execute(action, {}, 'GET').pipe(
      tap(response => {
        this.requestService.updateHeaders('Content-Type', 'application/json');
        this.requestService.responseType = undefined;
      })
    );
  }
  /**
   * @description Allow get all records accordint to filters selected
   * @param filter {object} Options available for adding filters
   * @param action {string} Parameter to represent a optional function available in the service
   * @returns \{{{Observable}}\} Return an observable for subscribing
   */
  getAll(filter: {}, action = ''): Observable<any> {
    action = `${this.collection}${action}`;
    if (filter) {
      action = action + filter;
    }
    return this.requestService.execute(action, filter, 'GET');
  }
  /**
   * @description send contract data component to component
   * @param
   */
  public contract = {};

  setContract(contract) {
    localStorage.setItem('contractSelected', JSON.stringify(contract));
  }

  getContract() {
    let contract = JSON.parse(localStorage.getItem('contractSelected') || '{}');

    if (contract['name']) {
      this.contract = contract;
      return contract;
    } else {
      this.router.navigate(['home/contratos']);
    }
  }

  getFechaHoy() {
    const today = new Date();

    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses van de 0 a 11, por lo que sumamos 1 y luego agregamos ceros a la izquierda si es necesario.
    const day = String(today.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }

  // Función para formatear la cantidad en formato de moneda mexicana (MXN)
  formatearMonto(monto: number): string {
    return monto.toLocaleString('es-MX', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  formateartitulos(cantidad: number): string {
    return cantidad.toLocaleString('en-US', { maximumFractionDigits: 6 });
  }
  // Convertir el número a formato de cadena con comas
  formatearCantidad(cantidad: number): string {
    return cantidad.toLocaleString('en-US', { maximumFractionDigits: 0 });
  }

  async decryptData(encryptedData, key) {
    const bytes = CryptoJS.AES.decrypt(encryptedData, key);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  }

  //LOCALIZACIO N
  ObtenerUbicacion() {
    setTimeout(() => {
      console.log(localStorage.getItem('latitude'))
      if (localStorage.getItem('latitude') === null) {
        this.requestLocationPermission();
      }
    }, 1000);
  }

  requestLocationPermission() {
    const userConfirmed = confirm(
      'Necesitamos acceder a tu ubicación mientras usas el portal para ofrecerte una mejor experiencia. ¿Deseas continuar?'
    );

    if (userConfirmed) {
      this.getLocation();
    }
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.ubicacionActual = position.coords;
          localStorage.setItem('latitude', this.ubicacionActual.latitude);
          localStorage.setItem('longitude', this.ubicacionActual.longitude);
        },
        error => {
          console.error('Error obteniendo la ubicación:', error);
        }
      );
    } else {
      alert('La geolocalización no es soportada por este navegador.');
    }
  }

  getChequeraContrato(contrato, operacion) {
    return new Promise((resolve, reject) => {
      this.collection = '/operacion/chequeracontrato';
      let verb = 'POST';
      let data = {
        contrato: contrato,
        operacion: operacion,
      };
      this.send(data, verb).subscribe(response => {
        let resp: any = response.data;
        if (resp.data.length > 0) {
          resp.data.forEach(async (element: any) => {
            let tarjeta = await this.decryptData(
              element.cuenta,
              'eressecretodeamor'
            );
            let cardsterics = await this.hideCardNumber(tarjeta);
            element.tarjetatext = element.name + ' - ' + cardsterics;
          });
          resolve(resp);
        } else {
          resp = {
            status: 200,
            message: 'Vacio',
          };
          reject(resp);
        }
      });
    });
  }

  getFormasPago(contrato, operacion) {
    return new Promise((resolve, reject) => {
      this.collection = '/operacion/formaspago';
      let verb = 'POST';
      let data = {
        contrato: contrato,
        operacion: operacion,
      };
      this.send(data, verb).subscribe(response => {
        let resp: any = response.data;
        if (resp.data.length > 0) {
          resolve(resp);
        } else {
          resp = [];
          reject(resp);
        }
      });
    });
  }

  async getIP() {
    let resp;
    await fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        resp = data.ip;
      })
      .catch(error => {
        console.error('Error al obtener la dirección IP:', error);
      });
    return resp;
  }

  // Función para ocultar números y mostrar asteriscos
  async hideCardNumber(cardNumber: string) {
    const lastFourDigits = cardNumber.slice(-4);
    const asterisks = '*'.repeat(cardNumber.length - 4);
    return `${asterisks}${lastFourDigits}`;
  }

  async getDataOperation(data) {
    if (data.tipooperacion === 'monto') {
      let operaciontitulos = data.cantidad_final / data.precio_titulo;
      var titulosHaciaAbajo = Math.floor(operaciontitulos);
      data.titulos = titulosHaciaAbajo;

      let operacionimporte = titulosHaciaAbajo * data.precio_titulo;
      let cantidadRedondeada: any = operacionimporte.toFixed(2);
      data.importe = Math.floor(cantidadRedondeada * 100) / 100;
      data.importe = this.formatearMonto(data.importe);
      data.titulos = this.formatearCantidad(data.titulos);
    }

    if (data.tipooperacion === 'titulos') {
      let operaciontitulo = data.cantidad_final * data.precio_titulo;
      data.titulos = data.cantidad_final;
      let cantidadRedondeada: any = operaciontitulo.toFixed(2);
      data.importe = Math.floor(cantidadRedondeada * 100) / 100;
      data.importe = this.formatearMonto(data.importe);
      data.titulos = this.formatearCantidad(data.titulos);
    }
    return data;
  }

  async getSaldos(contrato) {
    return new Promise((resolve, reject) => {
      this.collection = '/operacion/saldos';
      let verb = 'POST';
      let data = {
        contrato: contrato,
        fechaInicial: this.getFechaHoy(),
        fechaFinal: this.getFechaHoy(),
      };
      this.send(data, verb).subscribe(response => {
        resolve(response['data'].data[0]);
      });
    });
  }

  async getCheckTime() {
    return new Promise((resolve, reject) => {
      this.collection = '/configuraciones';
      let verb = 'POST';
      let data = {
        accion: 'read',
      };
      this.send(data, verb).subscribe(response => {
        let res = response.data.data.data.Items;
        this.session.timeout = res[0].timeout;
        resolve(res[0].timeout);
      });
    });
  }

  async activateClosesesion() {
    const timeout: any = await this.getCheckTime();

    const idle = new Idle()
      .whenNotInteractive()
      .within(parseInt(timeout))
      .do(() => this.showInactiveAlert())
      .start();
  }

  showInactiveAlert() {
    this.session.destroy();
  }
}
