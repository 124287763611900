<ng-container *ngIf="showLoader">
  <div class="loading">
    <div class="d-flex flex-column align-items-center h-100 center-gap">
      <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-8 col-sm-9 col-10 center-items">
        <img
          src="assets/images/logo-loader.png"
          alt="logo-compass"
          class="logo-loader" />
      </div>

      <div class="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</ng-container>
