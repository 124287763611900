<!-- <h3 for="Bienvenida" class="title">{{ title }}</h3> -->
<div class="row mt-2 mb-2">
  <div
    class="col-xl-3 col-lg-4 col-md-6"
    [ngClass]="{ 'mt-4': showDateRange === true }">
    <label class="form-check-label" for="empresa">Empresa</label>
    <select
      class="form-select"
      id="empresa"
      (change)="onChangeBusiness($event)">
      <option *ngIf="!contracts.length">Seleccione una empresa</option>
      <option
        [value]="i"
        [selected]="empresa.id === selectedBussines.id"
        *ngFor="let empresa of bussiness; let i = index">
        {{ empresa.name }}
      </option>
    </select>
  </div>
  <div
    class="col-xl-3 col-lg-4 col-md-6 mt-sm-4 mt-4"
    [ngClass]="{
      'mt-md-0': showDateRange === false
    }">
    <label class="form-check-label" for="contrato">Contrato</label>
    <select
      class="form-select"
      (change)="onChangeContract($event)"
      [disabled]="!contracts.length">
      <option *ngIf="!selectedContract">Seleccione un contrato</option>
      <option
        [value]="i"
        [selected]="contract === selectedContract"
        *ngFor="let contract of contracts; let i = index">
        Contrato {{ contract }}
      </option>
    </select>
  </div>
  <!-- mt-xl-0 mt-lg-5 mt-4 -->
  <div
    class="col-lg-4 col-md-4 col-sm-6 mt-md-0 mt-sm-4"
    [ngClass]="{ 'mt-xl-4 ': showDateRange === true }">
    <button
      class="btn btn-primary button mt-4"
      (click)="sendData()"
      [disabled]="disabledButtton">
      <span>Consultar</span>
      <span class="material-symbols-outlined arrow_icon"> chevron_right </span>
    </button>
  </div>
</div>

<div class="row mt-1" *ngIf="!showArrow">
  <div class="d-flex ver-mas">
    <div class="col-12">
      <button type="button pl-0" class="btn back" (click)="return()">
        <span class="material-symbols-outlined arrow arrow">
          chevron_left
        </span>
        <span>Volver</span>
      </button>
    </div>
  </div>
</div>
