import { Routes } from '@angular/router';

import { HomeComponent } from '../dashboard/home/home.component';
import { AjustesComponent } from '../dashboard/ajustes/ajustes.component';
import { ContratosComponent } from '../portal-clientes/contratos/contratos.component';
import { FlujosComponent } from '../portal-clientes/contratos/flujos/flujos.component';
import { MovimientosComponent } from '../portal-clientes/contratos/movimientos/movimientos.component';
import { PosicionComponent } from '../portal-clientes/contratos/posicion/posicion.component';
import { PrincipalComponent } from '../portal-clientes/contratos/principal/principal.component';
import { ValorPortafolioComponent } from '../portal-clientes/contratos/valor-portafolio/valor-portafolio.component';
import { DescargasPortalComponent } from '../portal-clientes/descargas/descargas.component';
import { PortalClientesComponent } from '../portal-clientes/portal-clientes.component';
import { ResumenComponent } from '../portal-clientes/resumen/resumen.component';
import { LoginComponent } from '../public/login/login.component';
import { NotFoundComponent } from '../public/not-found/not-found.component';
import { ResetPasswordComponent } from '../public/reset-password/reset-password.component';
import { SingUpComponent } from '../public/sing-up/sing-up.component';
import { AccessGuard } from '../util/canActive/access.guard';
import { appRoutesNames } from './app.routes.names';
import { BeneficiariosComponent } from '../beneficiarios/beneficiarios.component';
import { TableAdminComponent } from '../dashboard/table-admin/table-admin.component';
import { DescargasComponent } from '../dashboard/descargas/descargas.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { ManualUsuariosComponent } from '../dashboard/manual-usuarios/manual-usuarios.component';
import { LoginTwoFactorComponent } from '../public/login-two-factor/login-two-factor.component';
import { TransaccionesComponent } from '../portal-clientes/transacciones/transacciones.component';
import { CaracteristicasComponent } from '../portal-clientes/caracteristicas/caracteristicas.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'logintest',
    component: LoginComponent,
  },
  {
    path: appRoutesNames.LOGIN,
    component: LoginComponent,
  },
  {
    path: appRoutesNames.LOGIN_TWOFACTOR,
    component: LoginTwoFactorComponent,
  },
  {
    path: appRoutesNames.SINGUP,
    component: SingUpComponent,
  },
  {
    path: appRoutesNames.RECUPERAR,
    component: ResetPasswordComponent,
  },
  {
    path: appRoutesNames.DASHBOARD,
    component: DashboardComponent,
    canActivate: [AccessGuard],
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: appRoutesNames.HOME,
        component: HomeComponent,
      },
      {
        path: appRoutesNames.BENEFICIARIOS,
        component: BeneficiariosComponent,
      },
      {
        path: appRoutesNames.TABLA_ADMIN,
        component: TableAdminComponent,
      },
      {
        path: appRoutesNames.DESCARGAS,
        component: DescargasComponent,
      },
      {
        path: appRoutesNames.MANUALES,
        component: ManualUsuariosComponent,
      },
      {
        path: appRoutesNames.AJUSTES,
        component: AjustesComponent,
      },
    ],
  },

  {
    path: appRoutesNames.PORTAL_CLIENTES,
    component: PortalClientesComponent,
    canActivate: [AccessGuard],
    children: [
      {
        path: appRoutesNames.DESCARGAS,
        component: DescargasPortalComponent,
      },
      {
        path: appRoutesNames.CONTRATOS,
        component: ContratosComponent,
        children: [
          {
            path: '',
            component: PrincipalComponent,
          },
          {
            path: appRoutesNames.PRINCIPAL,
            component: PrincipalComponent,
          },
          {
            path: appRoutesNames.VALOR_PORTAFOLIO,
            component: ValorPortafolioComponent,
          },
          {
            path: appRoutesNames.POSICION,
            component: PosicionComponent,
          },
          {
            path: appRoutesNames.FLUJO,
            component: FlujosComponent,
          },
          {
            path: appRoutesNames.MOVIMIENTOS,
            component: MovimientosComponent,
          },
        ],
      },
      {
        path: appRoutesNames.TRANSACCION,
        component:TransaccionesComponent
      },
      {
        path:appRoutesNames.TRANSACCION+'/:id'+'/:serie',
        component: TransaccionesComponent
      },
      {
        path: appRoutesNames.FONDOS,
        component:CaracteristicasComponent
      },
      {
        path: '',
        redirectTo: appRoutesNames.CONTRATOS,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
