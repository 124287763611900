import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgChartsModule } from 'ng2-charts';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContractsListModule } from '../common/contractList/contractList.module';
import { SanitizeHtmlPipe } from '../common/pipes/sanitizerUrl.pipe';
import { TemplateModule } from '../template/template.module';
import { AjustesComponent } from '../dashboard/ajustes/ajustes.component';
import { ContratosComponent } from './contratos/contratos.component';
import { FlujosComponent } from './contratos/flujos/flujos.component';
import { MovimientosComponent } from './contratos/movimientos/movimientos.component';
import { PosicionComponent } from './contratos/posicion/posicion.component';
import { PrincipalComponent } from './contratos/principal/principal.component';
import { ValorPortafolioComponent } from './contratos/valor-portafolio/valor-portafolio.component';
import { DescargasPortalComponent } from './descargas/descargas.component';
import { PortalClientesComponent } from './portal-clientes.component';
import { ResumenComponent } from './resumen/resumen.component';
import { TransaccionesComponent } from './transacciones/transacciones.component';
import { TitleContainerService } from '../common/title-container/title-container.service';
import { SelectListModule } from '../common/select-list/select-list.module';
import { ConfirmarComponent } from './transacciones/confirmar/confirmar.component';
import { ProcesoComponent } from './transacciones/confirmar/proceso/proceso.component';
import { RecursosComponent } from './transacciones/recursos/recursos.component';
import { BreadcumModule } from '../common/breadcum/breadcum.module';
import { CaracteristicasComponent } from './caracteristicas/caracteristicas.component';


@NgModule({
  declarations: [
    PortalClientesComponent,
    ResumenComponent,
    AjustesComponent,
    DescargasPortalComponent,
    ContratosComponent,
    PrincipalComponent,
    ValorPortafolioComponent,
    PosicionComponent,
    FlujosComponent,
    MovimientosComponent,
    SanitizeHtmlPipe,
    TransaccionesComponent,
    ConfirmarComponent,
    ProcesoComponent,
    RecursosComponent,
    CaracteristicasComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TemplateModule,
    NgChartsModule,
    ContractsListModule,
    SelectListModule,
    BreadcumModule
  ],
  providers: [TitleContainerService],
  exports: [PortalClientesComponent],
})
export class PortalClientesModule {}
