import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/app/base/base.service';

@Component({
  selector: 'app-form-beneficiarios-finales',
  templateUrl: './form-beneficiarios-finales.component.html',
  styleUrls: ['./form-beneficiarios-finales.component.scss'],
})
export class FormBeneficiariosFinalesComponent
  extends BaseService
  implements OnInit
{
  /*
   * Send form to principal form
   */
  @Output() formReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  // On init
  ngOnInit(): void {
    this.myForm = this.fb.group({
      beneficiaries: this.fb.array([this.initBeneficiaries()]),
      effective_control: this.fb.array([this.initBeneficiaries()]),
    });

    this.formReady.emit(this.myForm);
  }

  get beneficiaries() {
    return this.myForm.get('beneficiaries') as FormArray;
  }

  get effective_control() {
    return this.myForm.get('effective_control') as FormArray;
  }

  /**
   * addBeneficiaries
   * Add person in Beneficiaries array
   */
  public addBeneficiaries() {
    this.beneficiaries.push(this.initBeneficiaries());
  }

  /**
   * addEffective_control
   * Add person in Effective_control array
   */
  public addEffective_control() {
    this.effective_control.push(this.initBeneficiaries());
  }

  /**
   * deleteBeneficiaries
   * Delete row in Beneficiaries array
   */
  public deleteBeneficiaries(i: number) {
    if (this.beneficiaries.length > 1) this.beneficiaries.removeAt(i);
  }

  /**
   * deleteEffective_control
   * Delete row in Effective_control array
   */
  public deleteEffective_control(i: number) {
    if (this.effective_control.length > 1) this.effective_control.removeAt(i);
  }

  initBeneficiaries() {
    return this.fb.group({
      cdi_id: ['', Validators.required],
      name: ['', Validators.required],
      address: ['', Validators.required],
      nacionality: ['', Validators.required],
      country: ['', Validators.required],
      us_person: ['', Validators.required],
      tin: ['', Validators.required],
      percentage: [
        '',
        [
          Validators.required,
          Validators.pattern('^[0-9]*$'),
          Validators.min(0),
          Validators.max(100),
        ],
      ],
    });
  }
}
