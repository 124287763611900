<div class="user-options-container">
  <li *ngFor="let item of options">
    <a class="h-100" (click)="routerToPath(item)">
      <div class="d-flex align-items-center">
        <img src="{{ item.icon }}" alt="{{ item.name }}" />
        <span class="nav-text title_menu"> {{ item.name }} </span>
      </div>
    </a>
  </li>
</div>
