<ng-container *ngIf="accion === 'comprar'">
  <div class="row mt-3 mb-3">
    <div class="col-4 mb-3">
      <p class="subtitle">Operación</p>
      <span class="description">{{ data.tituloOp }}</span>
    </div>
    <div class="col-4 mb-3">
      <p class="subtitle">Fondo</p>
      <span class="description">{{ data.fondo }}</span>
    </div>
    <div class="col-4 mb-3">
      <p class="subtitle">Serie</p>
      <span class="description">{{ data.serie }}</span>
    </div>
    <div class="col-4 mb-3">
      <p class="subtitle">Fecha de solicitud</p>
      <span class="description">{{ data.fecha_solicitud }}</span>
    </div>
    <div class="col-4 mb-3">
      <p class="subtitle">Fecha de ejecución</p>
      <span class="description">{{ data.fecha_ejecucion }}</span>
    </div>
    <div class="col-4 mb-3">
      <p class="subtitle">Fecha de liquidación</p>
      <span class="description">{{ data.fecha_liquidacion }}</span>
    </div>
    <div class="col-4 mb-3">
      <p class="subtitle">Precio por título</p>
      <span class="description"
        >$ {{ data.precio_titulo.toString() | number : '1.6-6' }}</span
      >
    </div>
    <div class="col-4 mb-3">
      <p class="subtitle">Importe a comprar</p>
      <span class="description">$&nbsp;{{ data.importe }}</span>
    </div>
    <div class="col-4 mb-3">
      <p class="subtitle">Importe solicitado</p>
      <span class="description">{{ data.cantidadtexto }}</span>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="accion === 'vender'">
  <div class="row mt-3 mb-3">
    <div class="col-4 mb-3">
      <p class="subtitle">Operación</p>
      <span class="description">{{ data.tituloOp }}</span>
    </div>
    <div class="col-4 mb-3">
      <p class="subtitle">Fondo</p>
      <span class="description">{{ data.fondo }}</span>
    </div>
    <div class="col-4 mb-3">
      <p class="subtitle">Serie</p>
      <span class="description">{{ data.serie }}</span>
    </div>
    <div class="col-4 mb-3">
      <p class="subtitle">Fecha de solicitud</p>
      <span class="description">{{ data.fecha_solicitud }}</span>
    </div>
    <div class="col-4 mb-3">
      <p class="subtitle">Fecha de ejecución</p>
      <span class="description">{{ data.fecha_ejecucion }}</span>
    </div>
    <div class="col-4 mb-3">
      <p class="subtitle">Fecha de liquidación</p>
      <span class="description">{{ data.fecha_liquidacion }}</span>
    </div>
    <div class="col-4 mb-3">
      <p class="subtitle">Precio por título</p>
      <span class="description"
        >$ {{ data.precio_titulo.toString() | number : '1.6-6' }}</span
      >
    </div>
    <div class="col-4 mb-3">
      <p class="subtitle">Títulos</p>
      <span class="description">{{ data.titulos }}</span>
    </div>
    <div class="col-4 mb-3">
      <p class="subtitle">Importe</p>
      <span class="description">$&nbsp;{{ data.importe }}</span>
    </div>
    <div class="col-4 mb-3">
      <p class="subtitle">Importe solicitado</p>
      <span class="description">{{ data.cantidadtexto }}</span>
    </div>
    <div class="col-4 mb-3" *ngIf="data.efectivo !== ''">
      <p class="subtitle">Importe a transfererencia</p>
      <span class="description">$&nbsp;{{ data.efectivo }}</span>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="accion === 'transferencia'">
  <div class="row mt-3 mb-3">
    <div class="col-4 mb-3">
      <p class="subtitle">Operación</p>
      <span class="description">{{ data.tituloOp }}</span>
    </div>
    <div class="col-4 mb-3">
      <p class="subtitle">Fecha de ejecución</p>
      <span class="description">{{ data.fecha_ejecucion }}</span>
    </div>
    <div class="col-4 mb-3">
      <p class="subtitle">Importe transferencia</p>
      <span class="description">$&nbsp;{{ data.importe }}</span>
    </div>
  </div>
</ng-container>
