import { Injectable } from '@angular/core';
import { SessionService } from 'src/app/util/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class ClosewindowsService {

  constructor(public sesion: SessionService) { }

  attachBeforeUnloadListener() {
    window.addEventListener('beforeunload', this.unloadHandler.bind(this));
  }

  private unloadHandler(event: Event) {
  
     // this.sesion.destroy();
  }


}
