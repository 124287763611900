import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/base/base.service';
import { MovementsInterface } from 'src/app/common/dtos/contractsDTOs';
import { ExportService } from 'src/app/util/exportFiles/export.service';

@Component({
  selector: 'app-movimientos',
  templateUrl: './movimientos.component.html',
  styleUrls: ['./movimientos.component.scss'],
})
export class MovimientosComponent extends BaseService implements OnInit {
  /**
   * @property {Array} List for rows table
   * @public
   */
  public data: MovementsInterface[] = [];
  /**
   * @property {Object} Selected bussines data
   * @private
   */
  private bussines: any = {};
  /**
   * @property {string} Id Contract for query params
   * @private
   */
  private idContract: string = '';
  /**
   * @property {string} Date start for query params
   * @private
   */
  private startDate: string = '';
  /**
   * @property {string} Date end for query params
   * @private
   */
  private endDate: string = '';

  isDownloading: boolean = false;

  ngOnInit(): void {
    this.loaderTransparent.show(true);
    this.dataBreadcum = [{
      breadcum: ['Home', 'Portal de clientes','Resumen del portafolio', 'Movimientos'],
      title: 'Movimientos',
      subTitle : '',
      icon:false
     }]
  }

  public getContactData(contactData) {
    this.loaderTransparent.show(true);
    this.bussines = contactData.bussines;
    this.idContract = contactData.contract;
    this.startDate = contactData.date;
    this.endDate = contactData.dateEnd;
    this.getAll(
      '/contract/' +
        this.idContract +
        '/movement?fechaInicial=' +
        this.startDate +
        '&fechaFinal=' +
        this.endDate +
        '&contrato=' +
        this.idContract
    ).subscribe(response => {
      let data = response['data'] ? response['data']['rows'] : [];

      this.data = data.map(item => ({
        ...item,
        entryDate: this.formatDate(item.entryDate),
        opDate: this.formatDate(item.opDate),
        dueDate: this.formatDate(item.dueDate),
        priceFormartZeros: Number(item.price).toFixed(6) || '0.000000',
      }));
      this.loaderTransparent.show(false);
    });
  }

  private formatDate(date) {
    let dateFormat = new Date(date.replace('-', '/').replace('-', '/'));

    let day = dateFormat.getDate().toString();
    let month = (dateFormat.getMonth() + 1).toString();
    let year = dateFormat.getFullYear().toString().substr(-2);

    return day + '/' + month + '/' + year;
  }

  /* 
  private formatDate(date) {
    let res = date.replace('-', '/').replace('-', '/');
    let dateParts = res.split('/');

    let dateinOrden =
      dateParts[1].toString() +
      '/' +
      dateParts[2].toString() +
      '/' +
      dateParts[0];

    let dateFormat = new Date(dateinOrden);

    let day = ('0' + dateFormat.getDate().toString()).slice(-2);
    let month = ('0' + (dateFormat.getMonth() + 1).toString()).slice(-2);
    let year = dateFormat.getFullYear().toString().substr(-2);

    return day + '/' + month + '/' + year;
  } */

  async printPDF() {
    const empresa = this.bussines.name;
    const fechaFinal = await this.formatDateYYYY(this.endDate);
    const fechaInicial = await this.formatDateYYYY(this.startDate);
    this.isDownloading = true;
    const id = this.idContract;

    const imgH = await ExportService.getImageFromLocal(
      '/assets/images/logo.png'
    );
    //var movementsDiv = await ExportService.convertHTMLtoImage('movementsDiv');

    const date = this.formatDateTime(new Date());

    const pdfTHeaders = [
      {
        text: 'Fecha de Ingreso',
        bold: true,
        fontSize: 8,
        alignment: 'center',
      },
      { text: 'Operación', bold: true, fontSize: 8, alignment: 'center' },
      { text: 'Fondo', bold: true, fontSize: 8, alignment: 'center' },
      { text: 'Serie', bold: true, fontSize: 8, alignment: 'center' },
      { text: 'Títulos', bold: true, fontSize: 8, alignment: 'center' },
      { text: 'Precio', bold: true, fontSize: 8, alignment: 'center' },
      { text: 'Importe', bold: true, fontSize: 8, alignment: 'center' },
      {
        text: 'Importe a Liquidar',
        bold: true,
        fontSize: 8,
        alignment: 'center',
      },
      {
        text: 'Fecha de Operación',
        bold: true,
        fontSize: 8,
        alignment: 'center',
      },
      {
        text: 'Fecha de Liquidación',
        bold: true,
        fontSize: 8,
        alignment: 'center',
      },
    ];

    let rows: [any[]] = [pdfTHeaders];

    for (let row of this.data) {
      let r = [
        { text: row.entryDate.toString(), fontSize: 8, alignment: 'center' },
        { text: row.operation.toString(), fontSize: 8, alignment: 'left' },
        { text: row.fund.toString(), fontSize: 8, alignment: 'left' },
        { text: row.serie.toString(), fontSize: 8, alignment: 'left' },
        { text: row.titles.toString(), fontSize: 8, alignment: 'left' },
        {
          text: '$' + row.priceFormartZeros.toString(),
          fontSize: 8,
          alignment: 'left',
        },
        {
          text: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(parseFloat(row.amount.toString().toString())),
          fontSize: 8,
          alignment: 'right',
        },
        {
          text: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(parseFloat(row.amountToPay.toString())),
          fontSize: 8,
          alignment: 'right',
        },
        { text: row.opDate.toString(), fontSize: 8, alignment: 'center' },
        { text: row.dueDate.toString(), fontSize: 8, alignment: 'center' },
      ];
      rows.push(r);
    }

    let docDefinition = {
      pageOrientation: 'landscape',
      pageMargins: [20, 150, 10, 30],
      header: function (currentPage, pageCount) {
        return [
          { image: imgH, width: 150, margin: 20 },
          {
            text: 'Contrato ' + id + ' | "' + empresa + '"',
            margin: [20, 0, 0, 10],
            bold: true,
          },
          {
            text: 'Desde ' + fechaInicial + ' hasta ' + fechaFinal,
            margin: [20, 0, 0, 10],
          },
        ];
      },
      footer: function (currentPage, pageCount) {
        return [
          {
            columns: [
              {
                text: 'Fecha y hora de descarga: ' + date,
                alignment: 'left',
                width: 'auto',
                margin: [20, 0, 0, 0],
              },
              {
                text: 'Página ' + currentPage.toString() + ' de ' + pageCount,
                alignment: 'right',
                margin: [0, 0, 20, 0],
              },
            ],
          },
        ];
      },
      content: [
        {
          text: 'Movimientos',
          margin: [0, 0, 0, 10],
          fontSize: 18,
          color: '#002FA7',
        },
        {
          layout: 'noBorders',
          table: {
            headerRows: 1,

            widths: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: rows,
          },
        },
      ],
      styles: {
        header: {
          background: 'red',
          lineHeight: 3,
        },
      },
    };

    //
    let rowsExcel: [any[]] = [
      [
        'Fecha de Ingreso',
        'Operación',
        'Fondo',
        'Serie',
        'Títulos',
        'Precio',
        'Importe',
        'Importe a Liquidar',
        'Fecha de Operación',
        'Fecha de Liquidación',
      ],
    ];

    for (let row of this.data) {
      let r = [
        insert(row.entryDate.toString(), -2, '20'),
        row.operation.toString(),
        row.fund.toString(),
        row.serie.toString(),
        row.titles.toString(),
        { t: 'n', v: row.priceFormartZeros.toString() },
        { t: 'n', v: parseFloat(row.amount.toString().toString()) },
        { t: 'n', v: row.amountToPay.toString() },
        insert(row.opDate.toString(), -2, '20'),
        insert(row.dueDate.toString(), -2, '20'),
      ];

      rowsExcel.push(r);
    }

    const tables = [{ rows: rowsExcel, name: 'Movimientos' }];
    await ExportService.exportToExcel(tables, 'movimientos.xlsx');

    ExportService.exportToPDF(docDefinition, false);

    this.isDownloading = false;
  }

  private formatDateTime(date) {
    let dateFormat = new Date(date);

    let day = ('0' + dateFormat.getDate().toString()).slice(-2);
    let month = ('0' + (dateFormat.getMonth() + 1).toString()).slice(-2);
    let year = dateFormat.getFullYear().toString().toString();
    let hour = ('0' + dateFormat.getHours().toString()).slice(-2);
    let minutes = ('0' + dateFormat.getMinutes().toString()).slice(-2);
    let seconds = ('0' + dateFormat.getSeconds().toString()).slice(-2);

    return (
      day +
      '/' +
      month +
      '/' +
      year +
      ' ' +
      hour +
      ':' +
      minutes +
      ':' +
      seconds
    );
  }

  private formatDateYYYY(date) {
    let dateFormat = new Date(date);

    let day = ('0' + dateFormat.getUTCDate().toString()).slice(-2);
    let mesTmp = dateFormat.getUTCMonth();
    let month = ('0' + (mesTmp + 1).toString()).slice(-2);
    let year = dateFormat.getFullYear().toString().toString();

    return day + '/' + month + '/' + year;
  }
}

const insert = function (strOrigen, index, str) {
  var ind = index < 0 ? strOrigen.length + index : index;
  return strOrigen.substring(0, ind) + str + strOrigen.substr(ind);
};
