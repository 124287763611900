<div class="w-100 h-100 p-4 pb-0 contenedor">
  <div class="custom_row align-items-end bg-white p-2">
    <div class="col-3">
      <p class="spanAlternativeSubTitle mb-2">Nombre cliente</p>
      <input class="input_primary f-14" type="text" [(ngModel)]="searchClient" placeholder="Ingresar nombre" />
    </div>
    <div class="col-3">
      <p class="spanAlternativeSubTitle mb-2">Estado</p>
      <select class="select_primary" [(ngModel)]="selectedValue">
        <option value="0" disabled selected>Seleccionar Estado</option>
        <option *ngFor="let user of selectOptions" [ngValue]="user.id">
          {{ user.state }}
        </option>
      </select>
    </div>
    <div class="col-1 ms-auto">
      <button class="btn-alternative" (click)="restart()">
        <img src="assets/icons/i-re-load.svg" alt="Recargar" />
      </button>
    </div>
    <div class="col-2">
      <button class="btn btn-primary w-100" (click)="search()">Buscar</button>
    </div>
  </div>
  <app-table [dataClients]="dataClients" [data]="data" [headers]="headers" [totalPages]="totalPages"
    (current_page)="onCurrentPage($event)" (elements_show)="elements_to_show($event)"></app-table>
</div>