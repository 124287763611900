import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseService } from 'src/app/base/base.service';
import { clientesInterface } from '../../dashboard/table-admin/clientes-interface';
import { ExportService } from 'src/app/util/exportFiles/export.service';

/**
 * @autor Carlos Santiago <carlos.sp@ix.agency>
 */

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent extends BaseService implements OnInit {
  /**
   * Variables
   */
  isDownloading: boolean = false;
  headersTable: any = [];
  total_pages: number = 0;
  clients: Array<clientesInterface> = [];
  data_to_show: Array<clientesInterface> = [];
  allClients: Array<clientesInterface> = [];

  /**
   * All data clients to export data
   */
  private _data: any;
  get data() {
    return this._data;
  }
  @Input()
  set data(value: any) {
    this._data = value;
    this.allClients = this._data;
  }

  /**
   * Some clients data to show
   */
  private _dataClients: any;
  get dataClients() {
    return this._dataClients;
  }
  @Input()
  set dataClients(value: any) {
    this._dataClients = value;
    this.clients = this._dataClients;
    this.data_to_show = this.clients;
  }

  /**
   * headers
   */
  private _headers: any;
  get headers() {
    return this._headers;
  }
  @Input()
  set headers(value: any) {
    this._headers = value;
    this.headersTable = this._headers;
  }

  /**
   * Current page
   */
  @Output() current_page = new EventEmitter<number>();
  page: number = 1;
  /**
   * total pages
   */
  private _totalPages: any;
  get totalPages() {
    return this._totalPages;
  }
  @Input()
  set totalPages(value: any) {
    this._totalPages = value;
    this.total_pages = this._totalPages;
  }

  ngOnInit(): void {
    this.current_page.emit(this.page);
  }

  /**
   * Its used to handling dates in fomart dd/mm/yyyy
   * @autor Carlos Santiago <carlos.sp@ix.agency>
   * @param {any} dateString - Date
   */
  formatDate(dateString) {
    var dateParts = dateString.split('/');
    var date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    return date;
  }

  /**
   * Its used to sort string columns
   * @autor Carlos Santiago <carlos.sp@ix.agency>
   * @param {any} column - Column to sort
   */
  asc: Boolean = true;
  sortTable(column) {
    this.asc = !this.asc;

    var columnFormat = this.formatDate(this.clients[0][column]);
    if (
      Object.prototype.toString.call(columnFormat) === '[object Date]' &&
      !isNaN(columnFormat.getTime())
    ) {
      this.clients = this.clients.sort((a, b) => {
        var aDate = this.formatDate(a[column]);
        var bDate = this.formatDate(b[column]);
        return this.asc ? (aDate > bDate ? -1 : 0) : aDate < bDate ? -1 : 0;
      });
    } else {
      this.clients = this.clients.sort((a, b) => {
        return this.asc
          ? a[column] > b[column]
            ? -1
            : 0
          : a[column] < b[column]
          ? -1
          : 0;
      });
    }
  }

  /**
   * Its used to export data to excel
   * @autor Carlos Santiago <carlos.sp@ix.agency>
   */
  async export() {
    this.isDownloading = true;
    let rowsExcel: Array<any> = [
      [
        'Cliente',
        'Estatus',
        'Última actualización',
        'Tipo de actualización',
        'Última notificación',
      ],
    ];
    for (var i = 0; i < this.allClients.length; i++) {
      rowsExcel.push([
        this.allClients[i].cliente,
        this.allClients[i].estatus,
        this.allClients[i].ultima_actualizacion,
      ]);
    }
    const tables = [{ rows: rowsExcel, name: 'Datos' }];
    await ExportService.exportToExcel(tables, 'Beneficiarios.xlsx');
    this.isDownloading = false;
  }

  /**
   * Its used to navigate through the arrows in table
   * @autor Carlos Santiago <carlos.sp@ix.agency>
   */
  arrowStart() {
    this.page = 1;
    this.current_page.emit(this.page);
  }
  arrowPrevious() {
    if (!(this.page - 1 < 1)) {
      this.page = this.page - 1;
      this.current_page.emit(this.page);
    }
  }
  arrowNext() {
    if (!(this.page + 1 > this.total_pages)) {
      this.page = this.page + 1;
      this.current_page.emit(this.page);
    }
  }
  arrowEnd() {
    this.page = this.total_pages;
    this.current_page.emit(this.page);
  }

  /**
   * Select for the rows to show
   * @autor Carlos Santiago <carlos.sp@ix.agency>
   */
  @Output() elements_show = new EventEmitter<number>();
  elements: number = 5;
  selectOptions = [
    { id: 1, value: 5 },
    { id: 2, value: 10 },
    { id: 3, value: 20 },
    { id: 4, value: 50 },
    { id: 5, value: 100 },
  ];
  valor: number = 0;
  /**
   * Its used to send the value that is selected
   * @autor Carlos Santiago <carlos.sp@ix.agency>
   * @param {any} column - Column to sort
   */
  onChange(valor) {
    this.valor = valor;
    this.elements = this.selectOptions[this.valor - 1].value;
    this.elements_show.emit(this.elements);
  }

  /**
   * Its used to search in the same table
   * @autor Carlos Santiago <carlos.sp@ix.agency>
   * @param {any} name - Name of the element to search
   */
  search_by_name: string = '';
  result: Array<any> = [];
  filterByName(name) {
    this.search_by_name = name;
    if (this.search_by_name === '') {
      this.result = [];
      this.data_to_show = this.clients;
    }
    this.clients.map(client => {
      for (const property in client) {
        if (client[property] === this.search_by_name) {
          this.result.push(client);
          this.data_to_show = this.result;
        }
      }
    });
  }

  /**
   * Its used to open a new tab for the pdf
   * @autor Carlos Santiago <carlos.sp@ix.agency>
   */
  openPdf(person: any) {
    this.loader.show(true);
    console.log(person);
    this.collection = '/beneficiarios/show-document';

    this.send(person).subscribe((response: any) => {
      console.log(response);
      if (response !== undefined) {
        window.open(response[0].url, '_blank');
      }
      this.loader.show(false);
    });
  }
}
