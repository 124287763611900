import { Component, Input, OnInit } from '@angular/core';
import { BaseService } from 'src/app/base/base.service';
import { sidebarMenuOptions } from '../../common/constants/constants';

@Component({
  selector: 'app-breadcum',
  templateUrl: './breadcum.component.html',
  styleUrls: ['./breadcum.component.scss'],
})
export class BreadcumComponent extends BaseService implements OnInit {
  @Input() data: any = [];

  breadcum: string[];
  title: string;
  subtitle: string;
  icon: boolean;
  path: string;

  ngOnInit(): void {
    this.icon = this.data[0].icon;
    this.title = this.data[0].title;
    this.subtitle = this.data[0].subTitle;
    this.breadcum = this.data[0].breadcum;
    this.path = this.data[0].path;
    console.log(sidebarMenuOptions);
  }
  redirecTo() {
    window.open(this.path);
  }
  redirectBreadcum(item) {
    console.log(item);
    const elemetselected = sidebarMenuOptions.items.find(
      element => element.name === item
    );

    switch (elemetselected?.name) {
      case 'Home':
        this.router.navigate(['dashboard/' + elemetselected.route]);
        sidebarMenuOptions.items.map(item => {
          if (item.isSelected) item.isSelected = false;
        });
        elemetselected.isSelected = true;
        break;
      case 'Portal de clientes':
        this.router.navigate(['portal-clientes/']);
        sidebarMenuOptions.items.map(item => {
          if (item.isSelected) item.isSelected = false;
        });
        elemetselected.isSelected = true;
        break;
      default:
        console.log('default', elemetselected);
        this.router.navigate(['portal-clientes/']);
        item === 'Resumen del portafolio'
          ? (sidebarMenuOptions.items[4].isSelected = true)
          : console.log('item false', item);
        break;
    }
    localStorage.setItem('itemSelected', elemetselected?.name || 'home');
  }
}
