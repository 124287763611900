import pdfmake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';

import * as XLSX from 'xlsx';

import { InjectorInstance } from '../../app.module';
import { HttpClient } from '@angular/common/http';

export class ExportService {
  private static http: HttpClient;

  static getClient() {
    if (!this.http) {
      this.http = InjectorInstance.get<HttpClient>(HttpClient);
    }
    return this.http;
  }

  static exportToPDF(docDefinition: any, isDownload: boolean) {
    pdfmake.vfs = pdfFonts.pdfMake.vfs;
    isDownload
      ? pdfmake.createPdf(docDefinition).download()
      : pdfmake.createPdf(docDefinition).open();
  }

  static async exportToExcel(tables: Array<any>, nameFile: string) {
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();

    for (var table of tables) {
      //let element = document.getElementById(table['id']);
      //const worksheet: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      let worksheet = XLSX.utils.aoa_to_sheet(table['rows']);

      XLSX.utils.book_append_sheet(workbook, worksheet, table['name']);
    }

    await XLSX.writeFile(workbook, nameFile);
  }

  static async convertHTMLtoImage(htmlID: string) {
    return new Promise((resolve, reject) => {
      let DATA: any = document.getElementById(htmlID);
      html2canvas(DATA).then(canvas => {
        const FILEURI = canvas.toDataURL('image/png');
        resolve(FILEURI);
      });
    });
  }

  static async getImageFromLocal(url: string) {
    const http = ExportService.getClient();
    return new Promise((resolve, reject) => {
      http.get(url, { responseType: 'blob' }).subscribe(res => {
        const reader = new FileReader();
        reader.onloadend = () => {
          var base64data = reader.result;
          resolve(base64data);
        };

        reader.readAsDataURL(res);
      });
    });
  }
}
