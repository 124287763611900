<div class="h-100 w-100 d-flex flex-column">
  <div class="bg-white p-4">
    <h3 for="Bienvenida" class="title">Bienvenido(a) {{ user | titlecase }}</h3>
  </div>
  <!-- <div class="mt-3 alert alert-primary" role="alert">
    <img src="../../../assets/icons/i-info.svg" alt="Informacion" />
    <span> Actualizar datos de contacto </span>
  </div> -->
  <ng-container *ngIf="showBanner">
    <p class="mt-3"></p>
    <!-- <p class="what-to-do mt-3">¿Qué quieres hacer hoy?</p> -->
    <div class="d-flex bannerContainer">
      <div class="d-flex flex-column bigger justify-text">
        <h1>{{ item_selected.name }}</h1>
        <p [innerHTML]="item_selected.description"></p>
        <p [innerHTML]="item_selected.operacion" *ngIf="validationtextoOperacion.allow" style="margin-top: -1.5rem;"></p>
        <button
          class="btn button btn-primary btn-into d-flex justify-content-center align-items-center"
          (click)="openView(item_selected.route)">
          <span>Ingresar</span>
          <span class="material-symbols-outlined f-14">
            arrow_forward_ios
          </span>
        </button>
        <a
          *ngIf="item_selected.openTab"
          class="btn button btn-consult d-flex align-items-center"
          routerLink="../{{ item_selected.openTabPath }}">
          <span>Manuales de usuario</span>
          <span class="material-symbols-outlined f-14 ms-2">
            arrow_forward_ios
          </span>
        </a>
      </div>
      <div class="d-flex flex-column justify-content-center f-20 phone">
        <span>{{ item_selected.name }}</span>
      </div>
    </div>
    <div class="d-flex flex-column bigger card phone">
      <p class="spanResponsiveColor" [innerHTML]="item_selected.description"></p>
      <button
        class="btn button btn-primary btn-into d-flex justify-content-center align-items-center"
        (click)="openView(item_selected.route)">
        <span>Ingresar</span>
        <span class="material-symbols-outlined f-14"> arrow_forward_ios </span>
      </button>
      <a
        *ngIf="item_selected.openTab"
        class="btn button btn-consult d-flex align-items-center"
        routerLink="../{{ item_selected.openTabPath }}">
        <span class="spanManualesColor f-18">Manuales de usuario</span>
        <span class="spanManualesColor material-symbols-outlined f-18 ms-2">
          arrow_forward_ios
        </span>
      </a>
    </div>
  </ng-container>

  <!-- <div class="d-flex flex-wrap cardsContainer">
    <div class="w-100 cardProductContainer" *ngFor="let item of menu_items">
      <div class="cardProduct" (click)="openView(item.route)">
        <img src="{{ item.image }}" alt="{{ item.name }}" />
        <p class="spanTitle second-blue mt">{{ item.name }}</p>
        <p class="description p-3">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus ipsum
          viverra etiam.
        </p>
      </div>
    </div>
  </div> -->
</div>
