import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class TitleContainerService {
  /**
   * @property {BehaviorSubject} A new instance from Behavior Subject
   * @private
   */
  private messageSource = new BehaviorSubject<any>({
    show: false,
    breadcum: [],
    title: '',
    subTitle: '',
  });
  /**
   * @property {Observable} Observable object
   * @public
   */
  currentMessage = this.messageSource.asObservable();
  constructor() {}
  /**
   * @description Insert a new status in the chain
   * @param showLoader {boolean} Show or Hide loader
   */
  show(show: boolean, breadcum?: string[], title?: string, subTitle?: string) {
    this.messageSource.next({
      show: show,
      breadcum: breadcum,
      title: title,
      subTitle: subTitle,
    });
  }
}
