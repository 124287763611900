/**
 * @author       Eduardo Leonardo Torres Guevara <eduardo.tg@ix.agency>
 * @copyright    2022 IX Agency.
 * @lastmodifiedDate 30.01.2023 14:49
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import {
  flowInterface,
  MovementsInterface,
  positionInterface,
  valuePortfolioInterface,
} from 'src/app/common/dtos/contractsDTOs';

import { BaseService } from '../../../base/base.service';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss'],
})
export class PrincipalComponent extends BaseService implements OnInit {
  /**
   * @property {Array} List for rows table
   * @public
   */
  public movements: MovementsInterface[] = [];
  /**
   * @property {Array} List for rows table
   * @public
   */
  public porfolio: valuePortfolioInterface[] = [];
  /**
   * @property {Array} List for rows table
   * @public
   */
  public flow: flowInterface[] = [];
  /**
   * @property {Array} Chart data
   * @public
   */
  public posicions: any = { amount: 0 };
  /**
   * @property {Boolean} Show Hide chart while fill data
   * @public
   */
  public showChart = false;
  /**
   * @property {string} Date start for query params
   * @public
   */
  public startDate: string = '';
  /**
   * @property {string} Title in contracts component
   * @public
   */
  public title = 'Tus contratos';
  /**
   * @property {string} Id Contract for query params
   * @public
   */
  public idContract: string = '';
  /**
   * @property {string} Date for query params
   * @private
   */
  private endDate: string = '';

  showPositionTable = false;

  rangeMovements = '';

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  // Pie
  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
        onClick: e => {},
      },
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            return this.formatLabelChartTooltip(tooltipItem);
          },
        },
      },
    },
    layout: {
      padding: {
        right: -10,
      },
    },
  };
  public pieChartData: ChartData<'pie', number[], string | string[]> = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [
          '#002FA7',
          '#7CAAF3',
          '#0D61E7',
          '#38A888',
          '#B8E994',
          '#BFBFBF',
          '#FA983A',
          '#EB2F06',
          '#3BBFE2',
        ],
      },
    ],
  };

  public pieChartType: ChartType = 'doughnut';

  ngOnInit(): void {
    this.loaderTransparent.show(true);
    this.dataBreadcum = [{
      breadcum: ['Home', 'Portal de clientes','Resumen de portafolio'],
      title: 'Resumen de portafolio',
      subTitle : '',
      icon:false
     }];

    setTimeout(() => {
      this.loaderTransparent.show(false);
    }, 4000);
    
  }

  async getContactData(contactData) {
    this.title = 'Resumen de contratos';
    this.loaderTransparent.show(true);
    this.idContract = contactData.contract.id;
    this.startDate = contactData.date;
    this.endDate = contactData.date;
    this.idContract = contactData.contract;
    this.getPortfolio();
    this.getMovements();
    this.getFlow();
    this.getPositions();

    setTimeout(() => {
      this.loaderTransparent.show(false);
    }, 4000);
  }

  async getMovements() {
    let dateStart = this.getRange();

    await this.getAll(
      '/contract/' +
        this.idContract +
        '/movement?fechaInicial=' +
        dateStart +
        '&fechaFinal=' +
        this.endDate +
        '&contrato=' +
        this.idContract
    ).subscribe(response => {
      let movements = response['data']
        ? response.data.rows.filter((month, idx) => idx < 10)
        : [];
      this.movements = movements.map(item => ({
        ...item,
        entryDate: this.formatDate(item.entryDate),
        opDate: this.formatDate(item.opDate),
        dueDate: this.formatDate(item.dueDate),
        priceFormartZeros: Number(item.price).toFixed(6) || '0.000000',
      }));
    });
  }

  async getPortfolio() {
    await this.getAll(
      '/contract/' +
        this.idContract +
        '/porfolio?fechaInicial=' +
        this.startDate +
        '&fechaFinal=' +
        this.endDate +
        '&contrato=' +
        this.idContract
    ).subscribe(response => {
      this.porfolio = response.data.generals;
    });
  }

  async getFlow() {
    await this.getAll(
      '/contract/' +
        this.idContract +
        '/flow?fecha=' +
        this.startDate +
        '&contrato=' +
        this.idContract
    ).subscribe(response => {
      let data = response['data'] ? response.data.rows : [];
      if (data.length > 0) {
        this.flow = data.map(item => ({
          concept: item[0].toString(),
          total: item[5],
        }));
      }
    });
  }

  async getPositions() {
    this.showPositionTable = false;
    this.showChart = false;
    await this.getAll(
      '/contract/' +
        this.idContract +
        '/position?fecha=' +
        this.startDate +
        '&contrato=' +
        this.idContract
    ).subscribe(response => {
      let data = response['data'] ? response.data.rows : [];

      this.posicions = response['data'] ? response.data.totals[0] : {};

      if (data.length > 0) {
        this.pieChartData.labels = response.data.rows.map(
          elent => elent.fund + '  ' + Number(elent.percent).toFixed(2) + '%'
        );
        this.pieChartData.datasets[0].data = response.data.rows.map(elent =>
          Number(elent.amount).toFixed(2)
        );
        this.showPositionTable = true;
        this.showChart = true;
      }
    });
  }

  private getRange() {
    let date = new Date(this.startDate.replace('-', '/').replace('-', '/'));

    const daysAgo = new Date(date.getTime());

    daysAgo.setDate(date.getDate() - 5);

    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString();
    let year = date.getFullYear().toString().substr(-2);

    let pastDay = daysAgo.getDate().toString();
    let pastMonth = (daysAgo.getMonth() + 1).toString();
    let pastYear = daysAgo.getFullYear().toString();

    this.rangeMovements =
      pastDay +
      '/' +
      pastMonth +
      '/' +
      pastYear.substr(-2) +
      ' - ' +
      day +
      '/' +
      month +
      '/' +
      year;

    return pastYear + '-' + pastMonth + '-' + pastDay;
  }

  private formatDate(date) {
    let dateFormat = new Date(date.replace('-', '/').replace('-', '/'));

    let day = dateFormat.getDate().toString();
    let month = (dateFormat.getMonth() + 1).toString();
    let year = dateFormat.getFullYear().toString().substr(-2);

    return day + '/' + month + '/' + year;
  }

  private formatLabelChartTooltip(value) {
    let formatNumber = Number(value.parsed);
    return (
      '$' +
      formatNumber.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  }
}
