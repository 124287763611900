<!-- <h3 for="Bienvenida" class="title">{{ title }}</h3> -->
<div class="contt mt-4 mb-4 me-4 ms-4">
  <div class="row mb-2" [ngClass]="{ 'mb-4': !showArrow }">
    <div
      class="col-xl-3 col-lg-4 col-md-6"
      [ngClass]="{ 'mt-4': showDateRange === true }">
      <label class="form-label" for="empresa">Cliente</label>
      <select class="form-select select" (change)="onChangeBusiness($event)">
        <option *ngIf="!contracts.length">Seleccione un cliente</option>
        <option
          [value]="i"
          [selected]="empresa.id === selectedBussines.id"
          *ngFor="let empresa of bussiness; let i = index">
          {{ empresa.name }}
        </option>
      </select>
    </div>
    <div
      class="col-xl-3 col-lg-4 col-md-6 mt-sm-4 mt-4"
      [ngClass]="{
        'mt-md-0': showDateRange === false
      }">
      <label class="form-label" for="contrato">Contrato</label>
      <select
        class="form-select select"
        (change)="onChangeContract($event)"
        [disabled]="!contracts.length">
        <option *ngIf="!selectedContract">Seleccione un contrato</option>
        <option
          [value]="i"
          [selected]="contract === selectedContract"
          *ngFor="let contract of contracts; let i = index">
          {{ contract }}
        </option>
      </select>
    </div>

    <div
      class="col-xl-2 col-lg-4 col-md-6 col-sm-12 mt-sm-4 mt-4"
      [ngClass]="{
        'mt-md-0': showDateRange === false
      }">
      <label
        class="titleRageData form-label"
        for="start date"
        *ngIf="showDateRange"
        >Desde</label
      >
      <label
        class="titleRageData form-label"
        for="start date"
        *ngIf="!showDateRange"
        >Fecha</label
      >
      <input
        type="date"
        class="form-control date"
        [class.titleRageData]="showDateRange"
        min="{{ minDay }}"
        max="{{ maxDay }}"
        [(ngModel)]="date"
        [disabled]="!selectedContract"
        (change)="onChangeDate()" />
    </div>
    <ng-container *ngIf="showDateRange">
      <div class="col-xl-2 col-lg-4 col-md-6 col-sm-12 mt-sm-4 mt-4">
        <label for="" class="titleRageData form-label">Hasta</label>
        <input
          type="date"
          class="form-control date"
          min="{{ date }}"
          max="{{ maxDay }}"
          [disabled]="!selectedContract"
          [(ngModel)]="dateEnd"
          (change)="onChangeDate()" />
      </div>
    </ng-container>

    <div
      class="col-lg-2 col-md-4 col-sm-6 mt-sm-4 mt-4 mt-xl-0 mt-4"
      [ngClass]="{ 'mt-xl-4 ': showDateRange === true }">
      <button
        class="btn btn-primary button"
        (click)="sendData()"
        [disabled]="disabledButtton">
        <span>Consultar</span>
        <span class="material-symbols-outlined arrow_icon">
          chevron_right
        </span>
      </button>
    </div>
  </div>

  <div class="row mt-1" *ngIf="!showArrow">
    <div class="d-flex ver-mas">
      <div class="col-12">
        <button type="button pl-0" class="btn back" (click)="return()">
          <span class="material-symbols-outlined arrow arrow">
            chevron_left
          </span>
          <span>Volver</span>
        </button>
      </div>
    </div>
  </div>
</div>
