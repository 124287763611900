<div class="not-found">
  <div class="box">
    <div class="d-flex justify-content-center align-items-center w-100 h-100">
      <div class="d-column">
        <div class="d-flex justify-content-center fourofour">
          <span>404</span>
        </div>
        <div class="not">Página no encontrada</div>
        <div class="sorry">Lo sentimos. La Página no fue encontrada</div>
        <div class="please d-flex justify-content-center">
          por favor vuelve al inicio
        </div>
        <div class="d-flex justify-content-center mt-5">
          <a routerLink="/dashboard/home" routerLinkActive="active"
            >Volver a la pagina principal</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
