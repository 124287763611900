<form [formGroup]="myForm" class="mt-4">
  <div class="custom_row">
    <div class="col-6 d-flex flex-column">
      <label class="weight-500" for="Razón social"> Razón social</label>
      <input type="text" class="input_primary" formControlName="razon_social" />
    </div>
    <div class="col-3 d-flex flex-column ml-5">
      <label class="weight-500" for="put_firts"> Rut</label>
      <input type="text" class="input_primary" formControlName="put_firts" />
    </div>
  </div>
  <div class="custom_row">
    <div class="col-6 d-flex flex-column">
      <label class="weight-500" for="Domicilio"> Domicilio</label>
      <input type="text" class="input_primary" formControlName="domicilio" />
    </div>
    <div class="col-3 d-flex flex-column ml-5">
      <label class="weight-500" for="Ciudad"> Ciudad </label>
      <input type="text" class="input_primary" formControlName="ciudad" />
    </div>
    <div class="col-3 d-flex flex-column ml-5">
      <label class="weight-500" for="Teléfono"> Teléfono</label>
      <input type="text" class="input_primary" formControlName="telefono" />
    </div>
  </div>
  <div class="custom_row">
    <div class="col-6 d-flex flex-column">
      <label class="weight-500" for="Constitución">Lugar de constitución</label>
      <input type="text" class="input_primary" formControlName="constitucion" />
    </div>
  </div>
  <div class="custom_row">
    <div class="col-6 d-flex flex-column">
      <label class="weight-500" for="Representante legal"
        >Nombre de representante legal</label
      >
      <input
        type="text"
        class="input_primary"
        formControlName="representante" />
    </div>
    <div class="col-3 d-flex flex-column">
      <label class="weight-500" for="Rut">Rut</label>
      <input type="text" class="input_primary" formControlName="put_sec" />
    </div>
  </div>
  <div class="custom_row">
    <div class="col-12 d-flex flex-column">
      <label class="weight-500" for="Sociedad">Tipo de sociedad </label>
      <div class="checkbox_row">
        <div class="form-check" *ngFor="let item of society_types">
          <input
            class="form-check-input"
            type="radio"
            value="{{ item.name }}"
            formControlName="society"
            (click)="removeOtherSociety()" />
          <label class="form-check-label" for="{{ item.name }}">
            {{ item.label }}
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            value="other"
            formControlName="society" />
          <label class="form-check-label" for="Otro"> Otro </label>
        </div>
        <input
          type="text"
          class="input_primary"
          value="Otro"
          formControlName="society_other"
          placeholder="Otro"
          [disabled]="myForm.value.society !== ''"
          id="otro" />
      </div>
    </div>
  </div>
  <div formArrayName="identify_people">
    <div
      class="custom_row"
      *ngFor="
        let item of myForm.controls['identify_people']['controls'];
        let i = index
      "
      [formGroupName]="i">
      <div class="col-2 d-flex flex-column">
        <label class="weight-500" for="Identificación"
          >RUT / TIN / Identificación</label
        >
        <input type="text" class="input_primary" formControlName="identify" />
      </div>
      <div class="col-4 d-flex flex-column">
        <label class="weight-500" for="Cargo">Cargo</label>
        <input type="text" class="input_primary" formControlName="position" />
      </div>
      <div class="col-5 d-flex flex-column">
        <label class="weight-500" for="Nombre">Nombre</label>
        <input type="text" class="input_primary" formControlName="name" />
      </div>
      <div class="d-flex align-items-end">
        <span
          class="material-symbols-outlined second-blue pointer"
          (click)="deletePerson(i)">
          delete
        </span>
      </div>
    </div>
  </div>

  <div class="custom_row">
    <div class="d-flex second-blue">
      <span class="pointer material-symbols-outlined pr-3"> add_circle </span>
      <p class="pointer ms-2" (click)="addPerson()">Agregar contacto</p>
    </div>
  </div>
</form>
