/**
 * @author       Eduardo Leonardo Torres Guevara <eduardo.tg@ix.agency>
 * @copyright    2022 IX Agency.
 * @lastmodifiedDate 28.11.2022 10:21
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';

import CONFIG from '../../../parameters.json';
import { LoaderService } from '../common/loader/loader.service';
import { LoaderTransparentService } from '../common/loaderTransparent/loaderTransparent.service';
import { NotificationService } from '../common/notification/notification.service';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class RequestService {
  /**
   * @property {HttpHeaders} A new instance config of HTT Header
   * @private
   */
  private httpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  /**
   * @property {string} URL to the API to use
   */
  public api = CONFIG['API'];

  responseType: any;

  constructor(
    private http: HttpClient,
    protected notification: NotificationService,
    protected loader: LoaderService,
    protected loaderTransparent: LoaderTransparentService,
    private router: Router,
    public auth: AuthService,
    @Inject(DOCUMENT) public document: Document
  ) {}
  /**
   * @description Allow replace a exist value in the specified header
   * @param header {string} Header name to update
   * @param value {string} New value in the header
   */
  updateHeaders(header: string, value: string): void {
    this.httpHeaders = this.httpHeaders.set(header, value);
  }
  /**
   * @description Allow remove a specified header
   * @param name Header name to delete
   */
  removeHeader(header: string): void {
    this.httpHeaders = this.httpHeaders.delete(header);
  }
  /**
   * @description Allow manage the text response to the view in case of an error
   * @param operation {string} The endpoint that was consume
   * @param result {any} Error response from the service
   */
  private handleError<T>(operation = 'Collection', result?: any) {
    let message = '';
    return (response: any): Observable<T> => {
      let error = '';
      response['userexist'] = false;
      response['error']['message']
        ? (error = response['error']['message'])
        : (error = response['message']);

      switch (error) {
        case 'The incoming token has expired':
          message = 'La sesíon ha expirado.';
          result['status'] = 'tokenExpired';
          this.destroy();
          break;
        case 'Usuario no existe en saleforce':
          message = 'Usuario sin contratos.';
          result['status'] = 'tokenExpired';
          this.destroy();
          break;
        case 'User already exists':
          message = 'El usuario ya esta registrado.  \n\n\n\nPor favor iniciar sesión';
          result['userexist'] = true;
          break;
        case 'El usuario no tiene contratos ligados':
          message = 'El usuario no tiene contratos ligados';
          this.destroy();
          break;
        case 'Error: MFA Incorrecto':
          message = 'Código incorrecto';
          break;
        case 'Usuario bloqueado, favor de esperar 15 minutos para desbloquearlo':
          message = error;
          break;
        case 'El usuario no existe.':
          message = error;
          break;
        case 'Invalid session for the user.':
          message = 'Código incorrecto';
          break;
        case '-1':
          message =
            'Lo sentimos, experimentamos un error temporal. Favor volver a intentar nuevamente';
          break;
        case '-2':
          message =
            'Correo no registrado, por favor contactarse con su asesor.';
          break;
        case '-3':
          message = 'Correo no vigente, por favor contactarse con su asesor.';
          break;
        case '-4':
          message =
            'Correo no habilitado, por favor contactarse con su asesor.';
          break;
        case '-5':
          message = 'Lo sentimos, experimentamos un error temporal.';
          break;
        case 'Verifica tu email':
          message = 'Correo no registrado, favor de registrarse.';
          break;
        case 'AlredyRegister':
          message = 'El usuario ya está registrado. \n\n\n\n Por favor iniciar sesión';
          result['userexist'] = true;
          break;
        case 'undefined':
          message = 'Lo sentimos, experimentamos un error temporal.';
          break;
        default:
          message =
            'Lo sentimos, experimentamos un error temporal. Favor de reintentar.';
          break;
      }
      this.showNotification(message);
      this.loader.show(false);
      this.loaderTransparent.show(false);
      return of(result as T);
    };
  }

  execute(
    collection: string,
    data: object,
    method = '',
    headers?
  ): Observable<any> {
    this.validateSesion();
    if (headers === true) {
      this.removeHeader('accessToken');
      this.removeHeader('refreshToken');
      this.removeHeader('authToken');
    }
    const service = this.api + collection;
    const options = {
      body: data,
      headers: this.httpHeaders,
      timeout:40000
    };

    return this.http.request(method, service, options).pipe(
      catchError(
        this.handleError(collection, {
          success: false,
          method: method,
          service: service,
          collection: collection,
          
        })
      )
    );
  }

  validateSesion() {
    if (localStorage.getItem('accessToken') != null) {
      this.updateHeaders(
        'accessToken',
        `${localStorage.getItem('accessToken')}`
      );
      this.updateHeaders(
        'refreshToken',
        `${localStorage.getItem('refreshToken')}`
      );
      this.updateHeaders('authToken', `${localStorage.getItem('authToken')}`);
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('authToken');
    }
  }

  public destroy(): void {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('authToken');
    localStorage.removeItem('email');
    localStorage.removeItem('userName');
    this.auth
      .logout({
        logoutParams: { returnTo: document.location.origin },
      })
      .subscribe(() => {
        setTimeout(() => {
          this.router.navigate(['login']);
        }, 1000);
      });
  }

  private showNotification(message: string): void {
    this.notification.show('block', '', message, '', 'error', '');
  }
}
