import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TemplateModule } from '../template/template.module';
import { BeneficiariosComponent } from './beneficiarios.component';
import { FormLegalPersonComponent } from './components/form-legal-person/form-legal-person.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBeneficiariosFinalesComponent } from './components/form-beneficiarios-finales/form-beneficiarios-finales.component';
import { FormClientDataComponent } from './components/form-client-data/form-client-data.component';
import { TableAdminComponent } from '../dashboard/table-admin/table-admin.component';
import { TableModule } from '../common/table/table.module';

// Local Components
@NgModule({
  imports: [
    CommonModule,
    TemplateModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
  ],
  declarations: [
    BeneficiariosComponent,
    FormLegalPersonComponent,
    FormBeneficiariosFinalesComponent,
    FormClientDataComponent,
    TableAdminComponent,
  ],
  exports: [BeneficiariosComponent],
})
export class BeneficiariosModule {}
