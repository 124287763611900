/**
 * @author       Eduardo Leonardo Torres Guevara <eduardo.tg@ix.agency>
 * @copyright    2022 IX Agency.
 * @lastmodifiedDate 08.09.2023 13:33
 */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/app/base/base.service';

@Component({
  selector: 'app-ajustes',
  templateUrl: './ajustes.component.html',
  styleUrls: ['./ajustes.component.scss'],
})
export class AjustesComponent extends BaseService implements OnInit {
  public showLogin: Boolean = true;
  /**
   * @property {String} Icon type/name
   * @public
   */
  public eyeIcon = 'visibility_off';
  /**
   * @property {String} Icon type/name
   * @public
   */
  public eyeIconConfirm = 'visibility_off';
  /**
   * @property {String} User name
   * @public
   */
  public userName = '';

  public showForm = false;
  public wachConfig = false;

  private okta_user_id = '';
  public edittime :boolean = false;
  public timechange :number = 2;//siempre habra un minimo si lo dejas en blanco que sera de 2 minutos

  ngOnInit(): void {
    let email = this.session.getEmail();
    this.verifiConfig(email);
    this.sendDataToBreadcum(['Home', 'Ajustes']);
    this.myForm = this.fb.group({
      code: ['', Validators.required],
      password: [
        '',
        Validators.compose([Validators.required, Validators.minLength(8)]),
      ],
      repeatePassword: [
        '',
        Validators.compose([Validators.required, Validators.minLength(8)]),
      ],
    });
    this.userName = localStorage.getItem('userName') || '';

    
  }

  verifiConfig(email){
    if (
      email === 'enrique.pina@cgcompass.com'||
      email === 'andre.millan.m@gmail.com'||
      email === 'afigueos@gmail.com'||
      email === 'miguel.tenorio@cgcompass.com'
    ) {
      this.wachConfig = true;
    }

  }

  /**
   * @description Send 2FA code new password and user
   * @returns void
   */
  
  onSubmitCode(form: FormGroup) {
    this.collection = '/auth/forgot/confirm';
    let verb = 'POST';
    this.loaderTransparent.show(true);
    let data = {
      email: localStorage.getItem('email') || '',
      user: this.okta_user_id,
      code: form.value.code.toString(),
      password: form.value.password,
    };
    this.sendWithoutHeaders(data, verb).subscribe(response => {
      if (response.message == 'Success') {
        this.notification.show(
          'block',
          '',
          'Cambio de contraseña exitoso.',
          '',
          'check',
          '/login'
        );
      }
      this.loaderTransparent.show(false);
    });
  }

  /**
   * @description Send 2FA code
   * @returns void
   */
  sendCode() {
    this.loaderTransparent.show(true);
    const email = localStorage.getItem('email');
    this.collection = '/auth/forgot';
    let verb = 'POST';
    //this.loaderTransparent.show(true);
    let data = {
      user: email,
    };
    this.sendWithoutHeaders(data, verb).subscribe(response => {
      if (response.message == 'Success') {
        this.notification.show('block', '', 'Código enviado.', '', 'check', '');
        this.showForm = true;
        this.okta_user_id = response.data.data[0].identities[0].user_id;
      }
      this.loaderTransparent.show(false);
    });
  }

  /**
   * @description Can change input typefrom password to text
   * @returns void
   */
  public showPass() {
    this.eyeIcon == 'visibility'
      ? (this.eyeIcon = 'visibility_off')
      : (this.eyeIcon = 'visibility');

    let inputPass = document.getElementById('password')!;

    if (inputPass.getAttribute('type') === 'password') {
      inputPass.setAttribute('type', 'text');
    } else {
      inputPass.setAttribute('type', 'password');
    }
  }
  /**
   * @description Can change input typefrom passwordConfirm to text
   * @returns void
   */
  public showPassConfirm() {
    this.eyeIconConfirm == 'visibility'
      ? (this.eyeIconConfirm = 'visibility_off')
      : (this.eyeIconConfirm = 'visibility');

    let inputPass = document.getElementById('passwordConfirm')!;

    if (inputPass.getAttribute('type') === 'password') {
      inputPass.setAttribute('type', 'text');
    } else {
      inputPass.setAttribute('type', 'password');
    }
  }

  back() {
    this.showForm = false;
  }

  edit(){
    this.edittime = true;
  }
  guardar(){
    this.loaderTransparent.show(true);
    this.collection = '/configuraciones';
    let verb = 'POST';
    let data = {
      timeout: this.timechange
    };
    this.send(data, verb).subscribe(response => {
      let message;
      if (response.status === 200) {//se realizo con exito
        message = response.data.data.data.message;
        this.notification.show('block', '', message, '', 'check', '');
        this.session.timeout = `${this.timechange}`;
        localStorage.setItem("timeout",this.session.timeout);

        let notification = document.getElementById('myNotification')!;
        setTimeout(() => {
          notification.style.display = 'none';
          this.router.navigate(['dashboard']);
        }, 1000);
      }
      this.edittime = false;
      this.activateClosesesion();
      this.loaderTransparent.show(false);
    });

  }

  private sendDataToBreadcum(breadcum: string[]) {
    this.titleContainerService.show(true, breadcum, 'Ajustes');
  }
}
