<ng-container *ngIf="showLoader">
  <div class="loading">
    <div
      class="d-flex flex-column align-items-center justify-content-center h-100">
      <div class="col-5 mb-3"></div>

      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</ng-container>
